import React, { createContext, type PropsWithChildren } from 'react';

import { Anchor, type AnchorProps } from '@atlaskit/primitives';
import { type ServedExperiment } from '@atlassian/search-experiment';

import { type QueryParams } from '../../../common/types';
import { extend } from '../../../common/utils/object-utils';
import { defaultGenerateSearchUrl, defaultOnNavigate } from '../../../common/utils/url';

export type LinkComponentProps = AnchorProps;

interface AppContextData {
	/**
	 * Optional component that is used to render links instead of a generic anchor tag.
	 */
	linkComponent: React.ComponentType<LinkComponentProps>;

	/**
	 * A function that maps a user's ID to the host application's profile page URL.
	 * When this returns null, the package knows that routing information is not
	 * available to reach a user's profile. UI may choose not to render links
	 * to user profiles then.
	 */
	mapUserIdToProfileUrl: (userId: string) => string | null;

	/**
	 * Retrieve feature gate state from stasig with Rovo vs Rovo overrides
	 * - See https://hello.atlassian.net/wiki/x/AZH-AwE
	 * - `@atlaskit/search-experiment` for implementation
	 *
	 * This function replaces
	 * - import { fg } from '@atlaskit/platform-feature-flags'
	 * - import { checkGate } from '@atlaskit/feature-gate-js-client';
	 *
	 * It will make use of overrides from Rovo vs Rovo mode if needed, calling to statsig if not (and if there are any errors)
	 *
	 */
	getFeatureGate: (name: string) => boolean;

	/**
	 * Retrieve frontend experiment state from statsig with Rovo vs Rovo overrides
	 * - See https://hello.atlassian.net/wiki/x/AZH-AwE
	 * - `@atlaskit/search-experiment` for implementation
	 *
	 */
	getFrontendExperiment: ({ abTestId }: { abTestId: string }) => ServedExperiment;

	/**
	 * A function that generates a search URL with the given query parameters.
	 */
	generateSearchUrl: (queryParams: QueryParams) => string;

	/**
	 * The query parameters that are currently in use.
	 */
	queryParams: QueryParams;

	/**
	 * Function that is used when changing the browser's URL. If not provided,
	 * the default will be an assignment to window.lcoation.href.
	 */
	onNavigate: (url: string, action: 'push' | 'replace') => void;
}

export type AppContextProviderProps = Partial<AppContextData>;

export const defaultAppContextData: AppContextData = {
	linkComponent: Anchor,
	mapUserIdToProfileUrl: () => null,
	generateSearchUrl: defaultGenerateSearchUrl,
	getFeatureGate: () => false,
	getFrontendExperiment: () => ({
		abTestId: '',
		cohort: '',
		serveExperiment: false,
	}),
	queryParams: {},
	onNavigate: defaultOnNavigate,
};

/**
 * Provides information about the host application that can't be stored in a state store,
 * e.g. a function.
 */
const AppContext = createContext<AppContextData>(defaultAppContextData);

export const useAppContext = () => React.useContext(AppContext);

export const AppContextProvider = ({
	children,
	...props
}: PropsWithChildren<AppContextProviderProps>) => (
	<AppContext.Provider value={extend(defaultAppContextData, props)}>{children}</AppContext.Provider>
);
