import {
	type Action,
	createActionsHook,
	createContainer,
	createHook,
	createSelector,
	createStateHook,
	createStore,
} from 'react-sweet-state';
import uuid from 'uuid/v4';

import { fg } from '@atlaskit/platform-feature-flags';
import { userQuery } from '@atlassian/search-client';
import { type SideBySideExperiment } from '@atlassian/search-experiment';

import { ApplicationModes } from '../../../common/constants/application-modes';
import { type PrimaryProductKey, ProductKeys } from '../../../common/constants/products';
import {
	productKeySchema,
	type SearchQueryStringParams,
	type SearchQueryStringParamsRaw,
} from '../../../common/constants/schemas/query-params';
import {
	type ExperimentConfig,
	type onInputDropdownResultSelect,
	type onInputSubmit,
	type UserDetailsWithPicture,
	type UserDetailsWithStatus,
	UserStatus,
} from '../../../common/constants/types';
import { type OutboundAuthConfigsType } from '../../../common/types';
import {
	areAllEqualQueryParams,
	parseQueryParams,
	toQueryParamsRaw,
	toQueryString,
} from '../../../common/utils/query-params';

const HELLO_TENANT_ID = 'a436116f-02ce-4520-8fbb-7301462a1674';

export type Features = {
	sideBySide?: {
		/**
		 * Whether the side-by-side layout is enabled.
		 */
		enabled: boolean;
		/**
		 * Experiments specifying the treatments to be implemented.
		 */
		experiments?: SideBySideExperiment[];
	};
	chromeExtension?: {
		enabled: boolean;
		onInputDropdownResultSelect: onInputDropdownResultSelect;
		onInputSubmit: onInputSubmit;
	};
};

export type TState = {
	aggAbsoluteUrl?: string;
	cloudId?: string;
	products: ProductKeys[];
	aggregatorHostName?: string;
	allFilterExcludedProducts: ProductKeys[];
	SAINHostName?: string;
	user?: UserDetailsWithPicture;
	outboundAuthConfigs: OutboundAuthConfigsType;
	primaryProduct?: PrimaryProductKey;
	searchSessionId: string;
	orgId?: string;
	analyticsAttributes?: Record<string, any>;
	queryParams: SearchQueryStringParams;
	prevQueryParams: SearchQueryStringParams;
	/**
	 * The ARIs for the spaces parsed from query params.
	 * This was added to support the query param as a space key
	 * while most backend systems require ARIs to operate.
	 **/
	initialSpaceARIs: string[];
	features?: Features;
	experimentConfig: ExperimentConfig;
	intercomHmac?: string;
	isAdminHubAIEnabled?: boolean;
	isNav4Enabled?: boolean;
	isNounsAvailable: boolean;
	isRovoEnabled: boolean;
	referrer?: string;
};

/**
 * This state object contains all the configuration which must be loaded to make the page functional.
 */
export const initialState = {
	products: [],
	allFilterExcludedProducts: [],
	outboundAuthConfigs: {},
	searchSessionId: uuid(),
	queryParams: {},
	prevQueryParams: {},
	initialSpaceARIs: [],
	experimentConfig: {
		backendExperiment: undefined,
		frontendExperiments: undefined,
	},
	isNounsAvailable: false,
	isRovoEnabled: false,
} satisfies TState;

export const actions = {
	setState:
		(partialState: Partial<TState>): Action<TState> =>
		({ setState }) => {
			setState(partialState);
		},
	loadUserPicture:
		(): Action<TState> =>
		({ setState, getState }) => {
			const userState = getState().user;
			const userId = userState?.id;

			if (!userState || !userId || !!userState.picture) {
				return;
			}

			userQuery(userId)
				.then((x) => {
					setState({
						user: {
							id: userState.id,
							name: userState.name,
							email: userState.email,
							picture: x.data?.user.picture ?? null,
						},
					});
				})
				.catch((_) => {
					// TODO: >>>> Send warning to sentry
				});
		},
	setQueryParams:
		(queryParam: Partial<SearchQueryStringParams>): Action<TState, BootstrapContainerProps> =>
		({}, { queryParams, queryParamsCallback = () => {} }) => {
			const areEqualQueryParams = areAllEqualQueryParams(
				queryParam,
				parseQueryParams(queryParams ?? {}),
			);

			if (areEqualQueryParams) {
				return;
			}

			queryParamsCallback(toQueryParamsRaw(queryParam));
		},
	syncQueryParams:
		(): Action<TState, BootstrapContainerProps> =>
		({ setState, getState }, { queryParams, queryParamsCallback = () => {} }) => {
			// queryParams is the query params coming from the URL or React Router in this case
			const {
				prevQueryParams, // Previously saved query params - this is to prevent infinitely updating queryParams
				queryParams: diffQueryParamsState, // The 'internal' query params state
			} = getState();

			const queryParamsURL = parseQueryParams(queryParams ?? {});

			const prevQueryParamsString = toQueryString(prevQueryParams);
			const diffQueryParamsURLString = toQueryString(queryParamsURL);
			const diffQueryParamsStateString = toQueryString(diffQueryParamsState);

			const allQueryParamsAreEqual = areAllEqualQueryParams(
				queryParamsURL,
				prevQueryParams,
				diffQueryParamsState,
			);

			if (allQueryParamsAreEqual) {
				return;
			}

			// Any changes from the url takes the highest precedence
			const hasURLQueryParams = !!queryParams;
			if (hasURLQueryParams && prevQueryParamsString !== diffQueryParamsURLString) {
				setState({
					prevQueryParams: queryParamsURL,
					queryParams: queryParamsURL,
				});
				return;
			}

			if (prevQueryParamsString !== diffQueryParamsStateString) {
				//  NOTE: If you are running the callback and you don't see the query params being updated,
				//  the callback might be sanitising the query parameters. This is the case for Confluence.
				queryParamsCallback(toQueryParamsRaw(diffQueryParamsState));
				setState({
					prevQueryParams: diffQueryParamsState,
					queryParams: diffQueryParamsState,
				});
				return;
			}
		},
	initializeSpaceARIsFromSpaceKeys:
		(spaceKeys: string[]): Action<TState, BootstrapContainerProps> =>
		async ({ setState, getState }, { getSpaceIdsFromSpaceKeys }) => {
			const { cloudId } = getState();
			if (spaceKeys.length === 0 || !getSpaceIdsFromSpaceKeys) {
				return;
			}
			const spaceIds = await getSpaceIdsFromSpaceKeys(spaceKeys);
			const spaceARIs = spaceIds.map((id) => `ari:cloud:confluence:${cloudId}:space/${id}`);
			setState({ initialSpaceARIs: spaceARIs });
		},
	clearSpaceARIs:
		(): Action<TState, BootstrapContainerProps> =>
		({ setState }) => {
			setState({ initialSpaceARIs: [] });
		},
	refreshSearchSessionId:
		(): Action<TState, BootstrapContainerProps> =>
		({ setState }) => {
			setState({ searchSessionId: uuid() });
		},
};

type TActions = typeof actions;

// Keep store private and use hooks to access state and actions
const Store = createStore<TState, TActions>({
	initialState,
	actions,
	name: 'bootstrap',
});

export type BootstrapContainerProps = {
	initialProps: Partial<TState>;
	queryParams?: Record<string, any>;
	/** Called when FPS query params should be updated in the application's router. */
	queryParamsCallback?: (queryParams: Partial<SearchQueryStringParamsRaw>) => void;
	/**
	 * @returns the space IDs for the given space keys. NOTE: these should be the space IDs, not ARIs.
	 * @example getSpaceIdsFromSpaceKeys(["STATUS"]); // Promise<["123454321"]>
	 **/
	getSpaceIdsFromSpaceKeys?: (spaceKeys: string[]) => Promise<string[]>;
};

export const BootstrapStoreContainer = createContainer<TState, TActions, BootstrapContainerProps>(
	Store,
	{
		onInit:
			() =>
			({ getState, setState, dispatch }, { initialProps, queryParams }) => {
				const parsedQueryParams = queryParams && parseQueryParams(queryParams);
				setState({
					...getState(),
					...initialProps,
					...(queryParams && { queryParams: parsedQueryParams }),
					referrer: parsedQueryParams?.referrer,
				});
				dispatch(actions.loadUserPicture());
			},
		onUpdate:
			() =>
			({ setState, getState, dispatch }, { initialProps, queryParams }) => {
				const parsedQueryParams = queryParams && parseQueryParams(queryParams);
				setState({
					...getState(),
					...initialProps,
					products: [...new Set([...getState().products, ...(initialProps?.products ?? [])])],
					referrer: parsedQueryParams?.referrer,
				});
				if (parsedQueryParams?.spaces) {
					dispatch(actions.initializeSpaceARIsFromSpaceKeys(parsedQueryParams.spaces));
				} else {
					dispatch(actions.clearSpaceARIs());
				}
				dispatch(actions.syncQueryParams());
			},
	},
);

// Hooks

// Note - This doesn't trigger a re-render
export const useBootstrapActions = createActionsHook(Store);

export const useBootstrap = createHook(Store);

/**
 * This hook gets the extra analytics attributes that are passed in as a prop from the consumer.
 * It should not be confused with the hooks for firing analytics events in `controllers/use-analytics`
 */
export const useAnalyticsAttributes = createHook(Store, {
	selector: (state) => state.analyticsAttributes,
});

export const useAvailableProducts = createHook(Store, {
	selector: (state) => state.products,
});

export const useBootstrapLoading = createHook(Store, {
	selector: (state) => state.products.length === 0,
});

export const usePrimaryProduct = createHook(Store, {
	selector: (state) => state.primaryProduct,
});

export const useSearchSessionId = createHook(Store, {
	selector: (state) => state.searchSessionId,
});

export const useIsHello = createHook(Store, {
	selector: (state) => state.cloudId === HELLO_TENANT_ID,
});

export const useFeatures = createHook(Store, {
	selector: (state) => state.features,
});
// TODO: Jlamoreaux remove these two hooks
export const useBackendExperiment = createHook(Store, {
	selector: (state) => state.experimentConfig.backendExperiment,
});

export const useFrontendExperiments = createHook(Store, {
	selector: (state) => state.experimentConfig.frontendExperiments,
});

export const useExperimentConfig = createHook(Store, {
	selector: (state) => state.experimentConfig,
});

export const useIntercomHmac = createHook(Store, {
	selector: (state) => state.intercomHmac,
});

export const useIsNounsAvailable = createHook(Store, {
	selector: (state) => state.isNounsAvailable,
});

// We're intentionally not exposing a simple boolean (eg. isRovoEnabled) as that will not scale if more modes are introduced.
export const useApplicationMode = createStateHook(Store, {
	selector: createSelector(
		[(state) => state.isRovoEnabled],
		(isRovoEnabled): ApplicationModes => getApplicationMode({ isRovoEnabled }),
	),
});

export const getApplicationMode = ({ isRovoEnabled }: { isRovoEnabled: boolean }) => {
	if (isRovoEnabled) {
		return ApplicationModes.Rovo;
	}
	return ApplicationModes.Unified;
};

export const useUser = createHook(Store, {
	selector: (state): UserDetailsWithStatus => {
		const user = state.user satisfies UserDetailsWithPicture | undefined;
		if (!user || !user.id) {
			return {
				status: UserStatus.Unauthenticated,
				id: null,
				name: null,
				email: null,
			};
		}

		if (user.id === 'unidentified') {
			return {
				status: UserStatus.Anonymous,
				...user,
			};
		}

		return {
			status: UserStatus.Authenticated,
			...user,
		};
	},
});

export const useReferrer = createHook(Store, {
	selector: (state) => state.referrer,
});

export const useOrgId = createHook(Store, {
	selector: (state) => state.orgId,
});

export const useCloudId = createHook(Store, {
	selector: createSelector([(state) => state.cloudId], (cloudId) => cloudId),
});

export const useIsAdminHubAIEnabled = createStateHook(Store, {
	selector: createSelector(
		[(state) => state.isAdminHubAIEnabled],
		(isAdminHubAIEnabled) => isAdminHubAIEnabled,
	),
});

export const useAutoSelectPrimaryProduct = createStateHook(Store, {
	selector: createSelector(
		[
			(state) => state.products,
			(state) => state.primaryProduct,
			(state) => state.isRovoEnabled,
			(state) => state.queryParams.product,
		],
		(products, primaryProduct, isRovoEnabled, currentSelectedProduct) => {
			const applicatonMode = getApplicationMode({ isRovoEnabled });
			const primaryProductToSelect = productKeySchema.safeParse(primaryProduct);

			const shouldAutoSelectPrimaryProduct =
				!currentSelectedProduct &&
				products &&
				products.length > 0 &&
				applicatonMode === ApplicationModes.Unified &&
				primaryProductToSelect.success &&
				primaryProductToSelect.data !== ProductKeys.Atlas &&
				fg('decouple_rovo_and_unified_search_experiences');

			if (shouldAutoSelectPrimaryProduct) {
				return primaryProductToSelect.data;
			} else {
				return null;
			}
		},
	),
});
