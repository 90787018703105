import { TypeFilterValueKey } from '../../filters';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { NotionFilterIcon } from './icon';
import { NotionIcon } from './search-result-icon';

export const notionConfig: ThirdPartyConfig = {
	displayName: 'Notion',
	id: 'notion',
	resourceType: 'notion-connector',
	icon: RovoLogoFromGlyph(NotionFilterIcon, 'Notion'),
	searchResultIcon: RovoLogoFromGlyph(NotionIcon, 'Notion'),
	integrationAri: 'ari:cloud:platform::integration/notion',
	typeFilterValues: [TypeFilterValueKey.Document],
	requiresOauth: true,
	isEnabled: () => false,
};
