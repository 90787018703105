import { TypeFilterValueKey } from '../../filters';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { GitlabFilterIcon } from './icon';
import { GitlabIcon } from './search-result-icon';
export const gitlabConfig: ThirdPartyConfig = {
	displayName: 'Gitlab',
	id: 'gitlab',
	resourceType: 'gitlab-connector',
	icon: RovoLogoFromGlyph(GitlabFilterIcon, 'Gitlab'),
	searchResultIcon: RovoLogoFromGlyph(GitlabIcon, 'Gitlab'),
	integrationAri: 'ari:cloud:platform::integration/gitlab',
	typeFilterValues: [
		TypeFilterValueKey.Repository,
		TypeFilterValueKey.Commit,
		TypeFilterValueKey.PullRequest,
		TypeFilterValueKey.Branch,
	],
	requiresOauth: true,
	isEnabled: () => false,
};
