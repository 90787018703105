import { fg } from '@atlaskit/platform-feature-flags';

import { TypeFilterValueKey } from '../../filters';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { OneDriveIcon } from './icon';

export const oneDriveConfig: ThirdPartyConfig = {
	displayName: 'OneDrive',
	id: 'onedrive',
	resourceType: 'onedrive-connector',
	icon: RovoLogoFromGlyph(OneDriveIcon, 'OneDrive'),
	integrationAri: 'ari:cloud:platform::integration/onedrive',
	typeFilterValues: [
		TypeFilterValueKey.Document,
		TypeFilterValueKey.Spreadsheet,
		TypeFilterValueKey.Presentation,
	],
	filters: ['lastUpdated', 'onlyTitleMatch'],
	requiresOauth: true,
	isEnabled: () => fg('enable_3p_search_onedrive'),
};
