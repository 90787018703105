import { TypeFilterValueKey } from '../../filters';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { AirtableFilterIcon } from './icon';
import { AirtableIcon } from './search-result-icon';

export const airtableConfig: ThirdPartyConfig = {
	displayName: 'Airtable',
	id: 'airtable',
	resourceType: 'airtable-connector',
	icon: RovoLogoFromGlyph(AirtableFilterIcon, 'Airtable'),
	searchResultIcon: RovoLogoFromGlyph(AirtableIcon, 'Airtable'),
	integrationAri: 'ari:cloud:platform::integration/airtable',
	typeFilterValues: [TypeFilterValueKey.Document],
	requiresOauth: true,
	isEnabled: () => false,
};
