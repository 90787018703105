import { addQueryParams } from '../add-query-params';
import { hashData } from '../search-result-utils';

const CONFLUENCE_VALUE_SEPARATOR = '---';
const CONFLUENCE_ATTRIBUTE_SEPARATOR = '___';

/**
 * Returns a URL with Confluence specific analytics query parameters. The
 * additionalAnalytics will be formatted as a single string and assigned to
 * the additional_analytics query parameter. This string will have the
 * following format:
 * <queryHash>---<value>___<key>---<value>___<key>---<value>
 */
export const getConfluenceUrlWithAnalyticsQueryParams = ({
	url,
	query,
	searchSessionId,
	additionalAnalytics,
}: {
	url: string;
	query: string;
	searchSessionId: string;
	additionalAnalytics?: Record<string, string>;
}) => {
	let events = [];

	if (query) {
		events.push(`queryHash${CONFLUENCE_VALUE_SEPARATOR}${hashData(query)}`);
	}

	if (additionalAnalytics) {
		Object.entries(additionalAnalytics).forEach(([attribute, value]) => {
			events.push(`${attribute}${CONFLUENCE_VALUE_SEPARATOR}${value}`);
		});
	}

	if (events.length === 0) {
		return addQueryParams(url, { search_id: searchSessionId });
	}

	const analyticsFormattedForUrlParsing = encodeURIComponent(
		events.join(CONFLUENCE_ATTRIBUTE_SEPARATOR),
	);

	return addQueryParams(url, {
		search_id: searchSessionId,
		additional_analytics: analyticsFormattedForUrlParsing,
	});
};

/**
 * Parses strings with the following format into an object:
 * <key>---<value>___<key>---<value>
 */
export const parseConfluenceAdditionalAnalyticsQueryParam = (
	additionalAnalytics?: string | null,
) => {
	const formattedEvents = {} as Record<string, string>;

	if (typeof additionalAnalytics !== 'string') {
		return formattedEvents;
	}

	const events = additionalAnalytics?.split(CONFLUENCE_ATTRIBUTE_SEPARATOR);
	events.forEach((event) => {
		const formattedEvent = event?.split(CONFLUENCE_VALUE_SEPARATOR);
		const hasProperlyFormattedEvent = formattedEvent.length === 2;

		if (hasProperlyFormattedEvent) {
			formattedEvents[formattedEvent[0]] = formattedEvent[1];
		}
	});

	return formattedEvents;
};

/**
 * Returns a URL with Jira specific analytics query parameters. The resultId
 * and projectId must be ARIs.
 */
export const getJiraUrlWithAnalyticsQueryParams = ({
	url,
	searchSessionId,
	resultId,
	projectId,
}: {
	url: string;
	searchSessionId: string;
	resultId: string;
	projectId?: string;
}) => {
	const jiraResultARIPattern = /^ari:cloud:jira:[a-z0-9-]+:[a-z]+\/[0-9]+$/;
	if (!resultId.match(jiraResultARIPattern)) {
		return url;
	}

	const jiraProjectARIPattern = /^ari:cloud:jira:[a-z0-9-]+:project\/[0-9]+$/;
	if (projectId && !projectId.match(jiraProjectARIPattern)) {
		return url;
	}

	let urlWithAnalyticsQueryParams = url;

	urlWithAnalyticsQueryParams = addQueryParams(urlWithAnalyticsQueryParams, {
		searchSessionId: searchSessionId,
		searchObjectId: resultId.split('/').pop(),
		searchContentType: resultId.split('/')[0].split(':').pop(),
	});

	if (projectId) {
		urlWithAnalyticsQueryParams = addQueryParams(urlWithAnalyticsQueryParams, {
			searchContainerId: projectId.split('/').pop(),
		});
	}

	return urlWithAnalyticsQueryParams;
};

export const getGenericUrlWithAnalyticsQueryParams = ({
	url,
	searchSessionId,
}: {
	url: string;
	searchSessionId: string;
}) => {
	return addQueryParams(url, { search_id: searchSessionId });
};
