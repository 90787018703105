import { type FC } from 'react';

import { type MessageDescriptor } from 'react-intl-next';

import type { IconProps } from '@atlaskit/icon';

import { WebDomainIcon } from './3p-product-configs/atlassian-web-crawler/icon';
import { GoalIcon, ProjectIcon, TagIcon } from './icons/nouns';
import { messages } from './messages';

export interface NounConfig {
	displayName: MessageDescriptor;
	displayNamePlural: MessageDescriptor;
	Icon: FC<IconProps>;
}

export const NounKeys = {
	project: 'project',
	goal: 'goal',
	tag: 'tag',
	AtlassianWebCrawler: 'atlassian-web-crawler',
};

export type NounKeys = (typeof NounKeys)[keyof typeof NounKeys];

export const nouns: Record<string, NounConfig> = {
	[NounKeys.project]: {
		displayName: messages.project,
		displayNamePlural: messages.projects,
		Icon: ProjectIcon,
	},
	[NounKeys.goal]: {
		displayName: messages.goal,
		displayNamePlural: messages.goals,
		Icon: GoalIcon,
	},
	[NounKeys.tag]: {
		displayName: messages.tag,
		displayNamePlural: messages.tags,
		Icon: TagIcon,
	},
	[NounKeys.AtlassianWebCrawler]: {
		displayName: messages.webPage,
		displayNamePlural: messages.webPages,
		Icon: WebDomainIcon,
	},
};

export const NOUNS_ORDER: NounKeys[] = [
	NounKeys.project,
	NounKeys.goal,
	NounKeys.tag,
	NounKeys.AtlassianWebCrawler,
];
