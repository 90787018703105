import type { SearchResult } from '../../../../common/types';

const SearchResultMercuryFocusArea = `
... on SearchResultMercuryFocusArea {
	lastModifiedDate
}
`;

export interface SearchResultMercuryFocusArea extends SearchResult {
	lastModifiedDate?: string;
	__typename: 'SearchResultMercuryFocusArea';
}

export type SearchMercuryPartial = SearchResultMercuryFocusArea;

export const isSearchResultMercuryFocusAreaPartial = (
	partial: SearchResult,
): partial is SearchResultMercuryFocusArea => {
	return partial?.id?.includes('mercury') && partial.__typename === 'SearchResultMercuryFocusArea';
};

export const isSearchMercuryPartial = (partial: SearchResult): partial is SearchMercuryPartial => {
	return isSearchResultMercuryFocusAreaPartial(partial);
};

export const getMercuryPartials = () => {
	return SearchResultMercuryFocusArea;
};
