import { fg } from '@atlaskit/platform-feature-flags';
import {
	confluencePagesQuery,
	type SearchEntitiesProductARI,
	searchEntitiesQuery,
} from '@atlassian/search-client';

import {
	ProductKeys,
	type ProductKeys1P,
	type ProductKeys3P,
} from '../../../../../common/constants/products';
import { getProducts3PFromConfigForFilter } from '../../../../../common/utils/filters';
import { createSingleSelectFilter } from '../../../../filters';
import { type FetchedSelectFilterOption } from '../../../../filters/base-select-filter/types';
import { type CloudConfig } from '../../../../filters/types';

const getContentId = (ari: string) => {
	const EXTRACT_ID = /ari:.*?:.*?:.*?:.*?\/(.*)?.*/;
	const matches = ari.match(EXTRACT_ID);
	// content id of the ari
	return matches && matches.length > 1 ? matches[1] : '';
};

const searchContent = async (query: string, config: CloudConfig) => {
	const location: SearchEntitiesProductARI = `ari:cloud:confluence::site/${config.cloudId}`;

	const contentPagesResponse = await searchEntitiesQuery({
		aggAbsoluteUrl: config.aggAbsoluteUrl,
		variables: {
			query,
			first: 10,
			filters: {
				entities: ['ati:cloud:confluence:page'],
				locations: [location],
			},
		},
	});

	const allContentPages =
		contentPagesResponse.data?.search?.results.edges.map((content) => ({
			title: content.node.title,
			id: content.node.id,
		})) || [];

	const contentOptions = allContentPages.map((content) => {
		const contentId = getContentId(content.id);
		return {
			trackingKey: contentId,
			value: contentId,
			label: content.title,
			queryParamValue: contentId,
		} satisfies FetchedSelectFilterOption;
	});

	return contentOptions;
};

const productKeys1P: ProductKeys1P[] = [ProductKeys.Confluence];

const originalProducts3P: ProductKeys3P[] = [];

const products3PFromConfig: ProductKeys3P[] = getProducts3PFromConfigForFilter('content');

const getProducts3P = () => {
	return fg('rovo-full-page-search-3p-static-config') ? products3PFromConfig : originalProducts3P;
};

export const filter = createSingleSelectFilter({
	id: 'content',
	products: [...productKeys1P, ...getProducts3P()],
	alwaysVisible: false,
	universal: false,
	queryParams: {
		key: 'ancestor',
		fetcher: async (queryParams, config) => {
			const initialContentARIs = queryParams.map(
				(contentId) => `ari:cloud:confluence:${config.cloudId}:page/${contentId}`,
			);

			const initialContentResponse = await confluencePagesQuery(initialContentARIs);

			const allContentPages =
				initialContentResponse.data?.confluence.pages.map((page) => ({
					title: page.title,
					id: page.id,
				})) || [];

			const contentOptions = allContentPages.map((content) => {
				const contentId = getContentId(content.id);
				return {
					trackingKey: contentId,
					value: contentId,
					label: content.title,
					queryParamValue: contentId,
				} satisfies FetchedSelectFilterOption;
			});
			return contentOptions;
		},
	},
	initialOptions: {
		fetcher: async (config) => {
			return searchContent('', config);
		},
	},
	lookup: {
		fetcher: async (query, config) => {
			return searchContent(query, config);
		},
	},
});
