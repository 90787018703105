/**
 * An icon not available in Design System yet.
 * Copied from https://bitbucket.org/atlassian/status-ui/src/master/packages/ds-icon/glyph/atlas/goal.tsx.
 */
import React from 'react';

export function GoalIcon() {
	return (
		<svg width="24" height="24" fill="none" viewBox="0 0 24 24">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19 12a7 7 0 1 1-14 0 7 7 0 0 1 14 0Zm2 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-7 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm2 0a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
				fill="currentColor"
			/>
		</svg>
	);
}
