import type {
	Maybe,
	SearchJiraBoardProductType,
	SearchJiraIssueProductType,
	SearchJiraProductType,
	SearchResult,
	SearchResultType,
} from '../../../../common/types';

/**
 * Hydrating the project avatar field causes additional latency so it is removed in this GraphQL Query
 * Investigation: https://hello.atlassian.net/wiki/x/iKKu8w
 */
const SearchResultJiraProject = `
... on SearchResultJiraProject {
  projectId
  projectKey
  projectType
  lastModifiedDate
  project {
    webUrl
  }
}`;

export interface SearchResultJiraProject extends SearchResult {
	projectId: string;
	projectKey: string;
	lastModifiedDate?: string;
	projectType: SearchJiraProductType;
	// Hydrated field
	project?: {
		webUrl?: string;
	};
	__typename: 'SearchResultJiraProject';
}

export const isSearchResultJiraProject = (
	partial: SearchResult,
): partial is SearchResultJiraProject => {
	return partial.__typename === 'SearchResultJiraProject';
};

/**
 * Hydrating issueTypeField and statusField have caused additional latency so it is removed in this GraphQL Query
 * Investigation: https://hello.atlassian.net/wiki/x/iKKu8w
 */
export const SearchResultJiraIssue = `
... on SearchResultJiraIssue {
  id
  title
  type
  description
  url
  iconUrl
  lastModifiedDate
  issueTypeId
  status {
    id
    name
  }
  issue {
    webUrl
	key
    fieldsById(ids: ["project"]) {
      edges {
        node {
          __typename
          name
          ... on JiraProjectField {
            project {
              id
              name
			  projectType
            }
          }
        }
      }
    }
  }
  socialSignal(tenantId: $tenantId) {
	    signal
		timestamp
		user {
			accountId
			name
			picture
			... on AtlassianAccountUser {
				email
				extendedProfile {
					jobTitle
				}
			}
	    }
  }
  __typename
}
`;

export type JiraProjectField = {
	__typename: 'JiraProjectField';
	name: 'Project';
	project: {
		name: string;
		id: string;
		projectType?: SearchJiraIssueProductType;
	};
};

export type JiraIssueField = JiraProjectField;

export type SearchResultJiraIssue = {
	id: string;
	title: string;
	url: string;
	iconUrl?: Maybe<string>;
	type: SearchResultType;
	description: string;
	lastModifiedDate?: string;
	status?: {
		name?: string;
	};
	// Hydrated field
	issue?: {
		webUrl?: string;
		key: string;
		fieldsById: {
			edges: {
				node: JiraProjectField;
			}[];
		};
	};
	socialSignal: Maybe<{
		/** Should be either "comment" , "edit", or "share". */
		signal: Maybe<string>;
		timestamp: Maybe<string>;
		user: Maybe<{
			accountId: string;
			name: string;
			picture: string;
			email: Maybe<string>;
			extendedProfile: Maybe<{
				jobTitle: Maybe<string>;
			}>;
		}>;
	}>;
	__typename: 'SearchResultJiraIssue';
};

export const mapJiraIssuePartial = (partial: SearchResultJiraIssue) => {
	return {
		...partial,
		url: partial.issue?.webUrl || partial.url,
	};
};

export const mapJiraProjectPartial = (
	partial: SearchResultJiraProject,
): SearchResultJiraProject => ({
	...partial,
	url: partial.project?.webUrl || partial.url,
});

export const isSearchResultJiraIssue = (
	partial: SearchResult,
): partial is SearchResultJiraIssue => {
	return partial.__typename === 'SearchResultJiraIssue';
};

export const isJiraProjectField = (partial: JiraIssueField): partial is JiraProjectField => {
	return partial.__typename === 'JiraProjectField' && partial.name === 'Project';
};

export const SearchResultJiraFilter = ``;

export interface SearchResultJiraFilter extends SearchResult {
	type: SearchResultType;
	__typename: 'SearchResultJiraFilter';
}

export const isSearchResultJiraFilter = (
	partial: SearchResult,
): partial is SearchResultJiraFilter => {
	return partial.__typename === 'SearchResultJiraFilter';
};

export const SearchResultJiraDashboard = ``;

export interface SearchResultJiraDashboard extends SearchResult {
	type: SearchResultType;
	__typename: 'SearchResultJiraDashboard';
}

export const isSearchResultJiraDashboard = (
	partial: SearchResult,
): partial is SearchResultJiraDashboard => {
	return partial.__typename === 'SearchResultJiraDashboard';
};

export const SearchResultJiraBoard = `
... on SearchResultJiraBoard {
  boardId
  container {
		__typename
	}
  product
}
`;

export type SearchResultJiraBoardProjectContainer = {
	projectId: string;
	projectKey: string;
	projectName: string;
	projectTypeKey: SearchJiraProductType;
};

export type SearchResultJiraBoardUserContainer = {
	userId: string;
	userAccountId: string;
	userName: string;
};

export type SearchResultJiraBoardContainer =
	| SearchResultJiraBoardProjectContainer
	| SearchResultJiraBoardUserContainer;

export interface SearchResultJiraBoard extends SearchResult {
	boardId?: Maybe<string>;
	container?: Maybe<SearchResultJiraBoardContainer>;
	product: SearchJiraBoardProductType;
	__typename: 'SearchResultJiraBoard';
}

export const isSearchResultJiraBoard = (
	partial: SearchResult,
): partial is SearchResultJiraBoard => {
	return partial.__typename === 'SearchResultJiraBoard';
};

export type SearchJiraPartial =
	| SearchResultJiraProject
	| SearchResultJiraIssue
	| SearchResultJiraFilter
	| SearchResultJiraDashboard
	| SearchResultJiraBoard;

export const isSearchJiraPartial = (partial: SearchResult): partial is SearchJiraPartial => {
	return (
		isSearchResultJiraProject(partial) ||
		isSearchResultJiraIssue(partial) ||
		isSearchResultJiraFilter(partial) ||
		isSearchResultJiraDashboard(partial) ||
		isSearchResultJiraBoard(partial)
	);
};

export const getJiraPartials = () => {
	return `
		${SearchResultJiraProject}
		${SearchResultJiraIssue}
		${SearchResultJiraFilter}
		${SearchResultJiraDashboard}
		${SearchResultJiraBoard}
	`;
};

export { fetchJiraFieldsQuery, type HydratedJiraIssue, type JiraHydrationData } from './hydration/';
