import { universalTypeFilterId } from '../../../../../common/constants/filters';
import { allProductKeys } from '../../../../../common/constants/products';
import { type TypeFilterValueKey } from '../../../../../common/utils/filters/constants';
import { type FetchedSelectFilterOption } from '../../../../filters/base-select-filter/types';
import { createSingleSelectFilter } from '../../../../filters/single-select-filter';
import { type CloudConfig } from '../../../../filters/types';

import { getAllTypeOptions, lookupOptions } from './utils';

export { type TypeFilterValueKey }; // TODO QS-5444 clean this up

const loadQueryParams = (queryParams: string[], config: CloudConfig) => {
	const allOptions = getAllTypeOptions(config);
	return allOptions
		.filter((option) => !option.isDefault)
		.filter((option) => queryParams.includes(option.queryParamValue))
		.map((option) => ({
			trackingKey: option.value,
			label: option.label,
			value: option.value,
			queryParamValue: option.queryParamValue,
			isDefault: option.isDefault,
		})) satisfies FetchedSelectFilterOption[];
};

const getInitialOptions = (config: CloudConfig) => {
	const allOptions = getAllTypeOptions(config);
	return allOptions.map((option) => ({
		trackingKey: option.value,
		label: option.label,
		value: option.value,
		queryParamValue: option.queryParamValue,
		isDefault: option.isDefault,
	})) satisfies FetchedSelectFilterOption[];
};

const sortCompareFn = (a: FetchedSelectFilterOption, b: FetchedSelectFilterOption) => {
	const allOption = getAllTypeOptions({ intl: undefined });
	return (
		allOption.findIndex((option) => option.queryParamValue === a.queryParamValue) -
		allOption.findIndex((option) => option.queryParamValue === b.queryParamValue)
	);
};

export const filter = createSingleSelectFilter({
	id: universalTypeFilterId,
	products: allProductKeys,
	alwaysVisible: true,
	universal: true,
	queryParams: {
		key: 'type',
		fetcher: async (queryParams, config) => {
			return loadQueryParams(queryParams, config);
		},
	},
	initialOptions: {
		fetcher: async (config) => {
			return getInitialOptions(config);
		},
	},
	lookup: {
		fetcher: async (query, config) => {
			// NOTE: this is the first step of filtering options
			//  the store does product specific filtering
			return lookupOptions(query, config);
		},
	},
	sortCompareFn,
});
