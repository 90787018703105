import { useEffect, useMemo, useState } from 'react';

import { fg } from '@atlaskit/platform-feature-flags';
import {
	get3pSearchConfig,
	getSearchConfig,
	getSearchConfigV2,
	getUserLoomAuthStatus,
	type TSearch3pConfigProps,
	type TSearchConfigAPIResponse,
} from '@atlassian/search-client';

import { ThirdPartyConfigs } from '../../common/constants/3p-product-configs';
import {
	allProductKeys,
	type PrimaryProductKey,
	PrimaryProductKeys,
	ProductKeys,
} from '../../common/constants/products';
import { type OutboundAuthConfigsType } from '../../common/types';
import { useAnalytics } from '../analytics';
import { useLogException } from '../error-boundary';

const EXTRACT_PRODUCT = /ari:cloud:(.*)?::/;
const EXTRACT_CLOUD_ID = /ari:cloud:.*?\/(.*)/;
const BITBUCKET_WORKSPACE_ARI = 'ari:cloud:bitbucket::workspace';

export type UseFetchConfigProps = {
	thirdPartyAbsoluteUrl?: string;
	thirdPartyDisabled?: boolean;
	userId?: string | null;
	cloudId?: string;
	aggAbsoluteUrl?: string;
	primaryProduct?: PrimaryProductKey;
	/**
	 * When passed this would be used as is to invoke the endpoint which returns search-configuration i.e. list of products accessible by the logged in user.
	 * This is useful for products like Trello where the API gateway is not on the host domain, but a completely different URL.
	 *
	 * No need to pass if the UI is being bootstrapped inside Jira, Confluence or any product tied to these sites.
	 */
	configFetchAbsoluteUrl?: string;
	aggregatorHostName?: string;
};

const getFirstCloudId = (firstPartyResponse: TSearchConfigAPIResponse) => {
	const match = firstPartyResponse.resources[0]?.id.match(EXTRACT_CLOUD_ID);
	const extractedCloudId = match ? match[1] : '';

	return extractedCloudId;
};

const isNounsAvailable = (firstPartyResponse?: TSearchConfigAPIResponse, cloudId?: string) => {
	if (!cloudId || !firstPartyResponse || !firstPartyResponse.siteMetadata) {
		return false;
	}
	return firstPartyResponse.siteMetadata.find((metadata) => metadata.siteId === cloudId)
		?.isNounsAvailable;
};

const isRovoEnabled = (firstPartyResponse?: TSearchConfigAPIResponse, cloudId?: string) => {
	if (!cloudId || !firstPartyResponse || !firstPartyResponse.siteMetadata) {
		return false;
	}
	return firstPartyResponse.siteMetadata.find((metadata) => metadata.siteId === cloudId)
		?.isRovoEnabled;
};

/**
 * A React hook which handles fetching of all the config necessary to bootstrap 1P + 3P results.
 */
export const useFetchConfig = ({
	cloudId: cloudIdProp,
	configFetchAbsoluteUrl,
	aggregatorHostName,
	userId,
	thirdPartyAbsoluteUrl,
	thirdPartyDisabled,
	primaryProduct,
}: UseFetchConfigProps) => {
	const [firstPartyResponse, setFirstPartyResponse] = useState<TSearchConfigAPIResponse>();
	const [isUserLoomAuthed, setIsUserLoomAuthed] = useState<boolean>(false);

	const [accessibleProductsConfig, setAccessibleProductsConfig] = useState<{
		products?: ProductKeys[];
		outboundAuthConfigs?: OutboundAuthConfigsType;
	}>({});
	const { fireAnalyticsEvent } = useAnalytics();

	const logException = useLogException();

	const firstCloudId = useMemo(() => {
		if (!cloudIdProp && firstPartyResponse) {
			return getFirstCloudId(firstPartyResponse);
		}
		return '';
	}, [firstPartyResponse, cloudIdProp]);

	const thirdPartyResponse = useThirdPartyConfig({
		cloudId: cloudIdProp || firstCloudId || '',
		userId: userId,
		absoluteUrl: thirdPartyAbsoluteUrl,
		disabled: thirdPartyDisabled,
	});

	useEffect(() => {
		//this endpoint only works in confluence and atlas, show loom to other products for now
		if (!isUserLoomAuthed) {
			if (
				primaryProduct === PrimaryProductKeys.Confluence ||
				primaryProduct === PrimaryProductKeys.Atlas
			) {
				getUserLoomAuthStatus({
					userId,
					cloudId: cloudIdProp,
				})
					.then((response) => setIsUserLoomAuthed(response?.status === 'mastered'))
					.catch((error) => {
						logException(error);

						setIsUserLoomAuthed(false);
					});
			} else {
				setIsUserLoomAuthed(true);
			}
		}
	}, [isUserLoomAuthed, setIsUserLoomAuthed, userId, logException, cloudIdProp, primaryProduct]);

	useEffect(() => {
		if (!firstPartyResponse) {
			const searchConfigParams = {
				cloudId: cloudIdProp,
				absoluteUrl: configFetchAbsoluteUrl,
				hostName: aggregatorHostName,
				userId,
			};

			const searchConfigPromise = fg('enable_search_config_v2')
				? getSearchConfigV2(searchConfigParams)
				: getSearchConfig(searchConfigParams);

			searchConfigPromise
				.then((response) => setFirstPartyResponse(response))
				.catch((error) => {
					logException(error);

					setFirstPartyResponse({
						resources: [],
						intercomHmac: '',
						siteMetadata: [],
					});
				});
		}
	}, [
		firstPartyResponse,
		setFirstPartyResponse,
		cloudIdProp,
		configFetchAbsoluteUrl,
		aggregatorHostName,
		userId,
		logException,
	]);

	useEffect(() => {
		if (
			thirdPartyResponse.productKeys &&
			firstPartyResponse &&
			!accessibleProductsConfig.products
		) {
			let allAccessibleProducts: ProductKeys[] = [];

			const firstPartyProducts = [
				...new Set(
					firstPartyResponse.resources.flatMap((resource) => {
						const { id } = resource;
						const match = id.match(EXTRACT_PRODUCT);

						if (match && match.length >= 2) {
							return [match[1]];
						}

						return [];
					}),
				),
			];

			if (firstPartyProducts.length === 0 && primaryProduct) {
				// Use the primary product if the 1P Search Config request fails
				allAccessibleProducts.push(primaryProduct as ProductKeys);
			} else {
				allAccessibleProducts.push(...(firstPartyProducts as ProductKeys[]));
			}

			// Remove Bitbucket from the list of resources if:
			// * The user doesn't have access to the Bitbucket product
			// * There are no bitbucket workspaces
			// See https://hello.atlassian.net/wiki/x/1hI6CQE for details
			if (
				allAccessibleProducts.includes('bitbucket') &&
				!firstPartyResponse.resources.find((r) => r.id.includes(BITBUCKET_WORKSPACE_ARI))
			) {
				allAccessibleProducts = allAccessibleProducts.filter(
					(product) => product !== ProductKeys.Bitbucket,
				);
			}

			// user may have loom entitlement but not the correct auth status
			if (!isUserLoomAuthed) {
				allAccessibleProducts = allAccessibleProducts.filter(
					(product) => product !== ProductKeys.Loom,
				);
			}

			// Allow 3P products only if a user has Rovo enabled.
			// Long-term it won't be needed if the 3p-configuration check ensures Rovo eligibility.
			// See https://hello.atlassian.net/wiki/spaces/SEARCH/pages/4469899124.
			const doesUserHaveRovo = isRovoEnabled(firstPartyResponse, cloudIdProp);
			if (!fg('decouple_rovo_and_unified_search_experiences') || doesUserHaveRovo) {
				const thirdPartyProductKeys = thirdPartyResponse.productKeys || [];
				allAccessibleProducts.push(...(thirdPartyProductKeys as ProductKeys[]));
				setAccessibleProductsConfig({
					products: allAccessibleProducts,
					outboundAuthConfigs: thirdPartyResponse.outboundAuthConfigs,
				});
			} else {
				setAccessibleProductsConfig({
					products: allAccessibleProducts,
					outboundAuthConfigs: {},
				});
			}
		}
	}, [
		isUserLoomAuthed,
		thirdPartyResponse,
		firstPartyResponse,
		setAccessibleProductsConfig,
		primaryProduct,
		fireAnalyticsEvent,
		logException,
		cloudIdProp,
		accessibleProductsConfig.products,
	]);

	return {
		accessibleProducts: accessibleProductsConfig.products,
		outboundAuthConfigs: accessibleProductsConfig.outboundAuthConfigs,
		firstCloudId,
		intercomHmac: firstPartyResponse?.intercomHmac,
		isNounsAvailable: isNounsAvailable(firstPartyResponse, cloudIdProp),
		isRovoEnabled: isRovoEnabled(firstPartyResponse, cloudIdProp),
	};
};

export const THIRD_PARTY_TYPES_ORIGINAL: Record<string, string> = {
	'ai-3p-connector:google-workspace': `google`,
	'ai-3p-connector:sharepoint-domain': `sharepoint`,
	'ai-3p-connector:slack-workspace': 'slack',
	//platform connector types use a different naming convention
	'sharepoint-connector': `sharepoint`,
	'webcrawler-connector': 'atlassian-web-crawler',
	'microsoft-teams-connector': 'teams',
	'github-connector': 'github',
	'figma-connector': 'figma',
	'onedrive-connector': 'onedrive',
	'dropbox-connector': 'dropbox',
	'google-calendar-connector': 'google-calendar',
	'gmail-connector': 'gmail',
	'airtable-connector': 'airtable',
	'smartsheet-connector': 'smartsheet',
	'notion-connector': 'notion',
	'miro-connector': 'miro',
	'zendesk-connector': 'zendesk',
	'docusign-connector': 'docusign',
	'lucid-connector': 'lucid',
	'monday-connector': 'monday',
	'azure-devops-connector': 'azure-devops',
	'gitlab-connector': 'gitlab',
	'confluence-dc-connector': 'confluence-dc',
	'canva-connector': 'canva',
	'outlook-calendar-connector': 'outlook-calendar',
	'outlook-email-connector': 'outlook-email',
	'asana-connector': 'asana',
	'box-connector': 'box',
} as const;

// Conditionally create the dynamic third-party types
const getThirdPartyTypesFromConfig = (): Record<string, string> => {
	// old sharepoint product key is put here (kept for backwards compatibility), as the new one is in the config
	const thirdPartyTypes: Record<string, string> = {
		'ai-3p-connector:sharepoint-domain': `sharepoint`,
	};

	ThirdPartyConfigs.forEach((config) => {
		if (config.resourceType) {
			thirdPartyTypes[config.resourceType] = config.id;
		}
	});

	return thirdPartyTypes;
};

export const getThirdPartyTypes = (): Record<string, string> => {
	return fg('rovo-full-page-search-3p-static-config')
		? getThirdPartyTypesFromConfig()
		: THIRD_PARTY_TYPES_ORIGINAL;
};

const get3pProductKeys = async ({ cloudId, absoluteUrl, userId }: TSearch3pConfigProps) => {
	const THIRD_PARTY_TYPES: Record<string, string> = getThirdPartyTypes();
	const resources = await get3pSearchConfig({ cloudId, absoluteUrl, userId });

	let productKeys = [] as ProductKeys[];
	let outboundAuthConfigs = {} as OutboundAuthConfigsType;

	resources.forEach((resource) => {
		const productKey = THIRD_PARTY_TYPES[resource.type] || '';
		if (productKey) {
			productKeys.push(productKey as ProductKeys);
			outboundAuthConfigs[productKey as ProductKeys] = {
				url: resource.outboundAuthUrl,
				userNeedsOAuth: !resource.isUserOAuthed,
			};
		}
	});

	productKeys = productKeys.filter((productKey) => allProductKeys.includes(productKey));

	return { productKeys, outboundAuthConfigs };
};

const EMPTY_RESPONSE: string[] = [];

export const useThirdPartyConfig = ({
	cloudId,
	absoluteUrl,
	userId,
	disabled,
}: Partial<TSearch3pConfigProps> = {}) => {
	const [config, setConfig] = useState<{
		productKeys?: string[];
		outboundAuthConfigs?: OutboundAuthConfigsType;
	}>({});
	const logException = useLogException();

	useEffect(() => {
		if (!config.productKeys) {
			if (!disabled && cloudId) {
				get3pProductKeys({ cloudId, absoluteUrl, userId })
					.then((data) => {
						setConfig({
							productKeys: data.productKeys,
							outboundAuthConfigs: data.outboundAuthConfigs,
						});
					})
					.catch((error) => {
						setConfig({
							productKeys: EMPTY_RESPONSE,
							outboundAuthConfigs: {},
						});
						logException(error);
					});
			} else {
				setConfig({
					productKeys: EMPTY_RESPONSE,
					outboundAuthConfigs: {},
				});
			}
		}
	}, [cloudId, absoluteUrl, userId, disabled, config, logException]);

	return config;
};
