import { useEffect, useMemo, useState } from 'react';

import { IntlShape, MessageDescriptor, useIntl } from 'react-intl-next';

import { fg } from '@atlaskit/platform-feature-flags';
import {
	type ConfluenceRangeFilter,
	type EntityATI,
	jiraIssueTypeJQLQuery,
	type SearchJiraProductType,
	type ThirdPartyFilters,
} from '@atlassian/search-client';

import { CUSTOM_OPTION_VALUE, type TypeFilterValueKey } from '../../../common/constants/filters';
import { ProductKeys } from '../../../common/constants/products';
import { type SelectedFiltersType } from '../../../common/constants/types';
import { type OutboundAuthConfigsType } from '../../../common/types';
import {
	baseIntegrationARIs,
	convertCustomDateToRange,
	convertLastUpdatedToDateRange,
	getConfluenceDateOptionFromCustomDates,
	getConfluenceDateOptionValues,
	getThirdPartyFilters,
} from '../../../common/utils/filters';
import { useFilters, useProducts, useTypeEntities } from '../../store/search';
import {
	assigneeFilter,
	contentFilter,
	contributorFilter,
	labelFilter,
	lastUpdatedFilter,
	ownerFilter,
	projectFilter,
	spaceFilter,
	titleMatchFilter,
	verifiedFilter,
} from '../../store/search/filters';
import { isSelectFilter } from '../base-select-filter';
import { type FilterStates } from '../types';

import { messages } from './messages';

const getSelectFilterValue = (filter?: FilterStates) => {
	return isSelectFilter(filter?.type) ? filter.value : [];
};

const getSelectFilterCustomOptionValue = (filter?: FilterStates) => {
	return isSelectFilter(filter?.type) ? filter.customValue : {};
};

const getBooleanFilterValue = (filter?: FilterStates) => {
	return filter?.type === 'boolean' ? filter.value : false;
};

const getSelectedDateRange = (filter?: FilterStates) => {
	const selectedDateOption = getSelectFilterValue(filter).at(0);
	if (selectedDateOption && selectedDateOption !== CUSTOM_OPTION_VALUE) {
		return convertLastUpdatedToDateRange(selectedDateOption);
	} else if (selectedDateOption === CUSTOM_OPTION_VALUE) {
		const customDateRange = getSelectFilterCustomOptionValue(filter);
		if (customDateRange?.from || customDateRange?.to) {
			return convertCustomDateToRange(customDateRange.from, customDateRange.to);
		}
	}
	return;
};

const getFilterDateRange = (filter?: FilterStates): ConfluenceRangeFilter[] => {
	const selectedDateOption = getSelectFilterValue(filter).at(0);

	if (selectedDateOption && selectedDateOption !== CUSTOM_OPTION_VALUE) {
		return [getConfluenceDateOptionValues(selectedDateOption)];
	} else if (selectedDateOption === CUSTOM_OPTION_VALUE) {
		const customDateRange = getSelectFilterCustomOptionValue(filter);
		if (customDateRange?.from && customDateRange?.to) {
			return [getConfluenceDateOptionFromCustomDates(customDateRange.from, customDateRange.to)];
		}
	}
	return [];
};

type JiraProductKeys =
	| typeof ProductKeys.Jira
	| typeof ProductKeys.JiraProductDiscovery
	| typeof ProductKeys.JiraServiceManagement;

const getJPDProjects = (): SearchJiraProductType[] =>
	// eslint-disable-next-line @atlaskit/platform/no-module-level-eval
	fg('enable_1p_search_jpd') ? ['product_discovery'] : [];

const getJSMProjects = (): SearchJiraProductType[] =>
	// eslint-disable-next-line @atlaskit/platform/no-module-level-eval
	fg('enable_1p_search_jsm') ? ['service_desk'] : [];

export const JiraProductProjectFilterKey: {
	[K in JiraProductKeys]: () => SearchJiraProductType[];
} = {
	[ProductKeys.Jira]: () => ['software', 'business'],
	[ProductKeys.JiraProductDiscovery]: getJPDProjects,
	[ProductKeys.JiraServiceManagement]: getJSMProjects,
};

export const getJiraProjectFilter = (selectedProduct?: ProductKeys): SearchJiraProductType[] => {
	// when all products are searched
	if (!selectedProduct) {
		return Object.values(JiraProductProjectFilterKey).flatMap((x) => x());
	}

	switch (selectedProduct) {
		case ProductKeys.Jira:
		case ProductKeys.JiraProductDiscovery:
		case ProductKeys.JiraServiceManagement:
			return JiraProductProjectFilterKey[selectedProduct]();
	}

	return [];
};

// providing dummy value when no results returned so that it still filters on the type
// otherwise an empty array would return all types and not match what the user filters
export const DUMMY_JIRA_ISSUE_TYPE_ID = 'DUMMY_JIRA_ISSUE_TYPE_ID';

export const getJiraIssueTypesFilter = async (
	cloudId: string | undefined,
	intl: IntlShape,
	issueType: Partial<TypeFilterValueKey>,
): Promise<string> => {
	if (!cloudId) {
		return DUMMY_JIRA_ISSUE_TYPE_ID;
	}
	const searchString = intl.formatMessage(messages[issueType] as MessageDescriptor);
	const issueTypes = await jiraIssueTypeJQLQuery(cloudId, searchString);

	const ids = issueTypes?.data?.jira.jqlBuilder.fieldValues.edges;
	if (!ids || ids.length === 0) {
		return DUMMY_JIRA_ISSUE_TYPE_ID;
	}
	if (ids.length > 1) {
		const issueTypeNode = ids.find((id) => id.node?.displayName === searchString);
		if (!issueTypeNode) {
			return DUMMY_JIRA_ISSUE_TYPE_ID;
		}
		const issueType = issueTypeNode.node?.issueTypes[0];
		return issueType && issueType.issueTypeId ? issueType.issueTypeId : DUMMY_JIRA_ISSUE_TYPE_ID;
	} else {
		const node = ids[0].node;
		if (!node) {
			return DUMMY_JIRA_ISSUE_TYPE_ID;
		}
		return node.issueTypes[0].issueTypeId || DUMMY_JIRA_ISSUE_TYPE_ID;
	}
};

export const useJiraIssueTypeFilters = ({
	cloudId,
	outboundAuthConfigs,
}: {
	cloudId: string | undefined;
	outboundAuthConfigs: any;
}) => {
	const { selectedEntities } = useTypeEntities({ outboundAuthConfigs });
	const intl = useIntl();
	const [jiraIssueTypeFilters, setJiraIssueTypeFilters] = useState<string[]>([]);

	useEffect(() => {
		const getJiraIssues = async () => {
			if (selectedEntities.includes('incident')) {
				const issueType = await getJiraIssueTypesFilter(cloudId, intl, 'incident');
				setJiraIssueTypeFilters([issueType]);
			} else {
				setJiraIssueTypeFilters([]);
			}
		};
		getJiraIssues();
	}, [selectedEntities, cloudId, intl]);

	return { jiraIssueTypeFilters };
};

export const useSelectedFilters = ({
	outboundAuthConfigs,
	cloudId,
}: {
	outboundAuthConfigs: OutboundAuthConfigsType;
	cloudId: string | undefined;
}): {
	selectedFilters: SelectedFiltersType;
	unselectedProductsThirdPartyFilters: ThirdPartyFilters;
	hasFiltersSelected: boolean;
	selectedEntities: string[];
	entitiesForResultsCountQuery: EntityATI[];
	entities: EntityATI[];
} => {
	const filters = useFilters();
	const { selectedProduct, filteredProducts, availableProducts } = useProducts();
	const { selectedEntities, entitiesForQuery, entitiesForResultsCountQuery } = useTypeEntities({
		outboundAuthConfigs,
	});
	const { jiraIssueTypeFilters } = useJiraIssueTypeFilters({ cloudId, outboundAuthConfigs });
	const titleMatchValue = getBooleanFilterValue(filters[titleMatchFilter.id]);
	const selectedDateRange = getSelectedDateRange(filters[lastUpdatedFilter.id]);
	const filterDateRange = getFilterDateRange(filters[lastUpdatedFilter.id]);
	const commonContributors = fg('universal-contributor-filter')
		? getSelectFilterValue(filters[contributorFilter.id]).length
			? getSelectFilterValue(filters[contributorFilter.id])
			: undefined
		: undefined;

	const selectedFilters = useMemo(
		() =>
			({
				commonFilters: {
					range: {
						lastModified: selectedDateRange,
					},
					contributorsFilter: commonContributors,
				},
				jiraFilters: {
					issueFilter: {
						projectARIs: getSelectFilterValue(filters[projectFilter.id]),
						assigneeARIs: getSelectFilterValue(filters[assigneeFilter.id]),
						issueTypeIDs: fg('enable_jsm_incident_type') ? jiraIssueTypeFilters : [],
					},
					projectFilter: {
						projectTypes: getJiraProjectFilter(selectedProduct),
					},
				},
				confluenceFilters: {
					spacesFilter: getSelectFilterValue(filters[spaceFilter.id]),
					isVerified: getBooleanFilterValue(filters[verifiedFilter.id]) || undefined,
					contributorsFilter: getSelectFilterValue(filters[contributorFilter.id]),
					ancestorIdsFilter: getSelectFilterValue(filters[contentFilter.id]),
					range: selectedProduct !== ProductKeys.Confluence ? filterDateRange : [],
					owners: getSelectFilterValue(filters[ownerFilter.id]),
					labelsFilter: getSelectFilterValue(filters[labelFilter.id]),
				},
				thirdPartyFilters: getThirdPartyFilters(
					filteredProducts,
					titleMatchValue,
					selectedEntities as TypeFilterValueKey[],
					outboundAuthConfigs,
					baseIntegrationARIs,
					filterDateRange,
				),
			}) satisfies SelectedFiltersType,
		[
			filterDateRange,
			filteredProducts,
			filters,
			outboundAuthConfigs,
			selectedDateRange,
			selectedEntities,
			selectedProduct,
			titleMatchValue,
			commonContributors,
			jiraIssueTypeFilters,
		],
	);

	const unselectedProductsThirdPartyFilters = useMemo(
		() =>
			getThirdPartyFilters(
				availableProducts,
				titleMatchValue,
				selectedEntities as TypeFilterValueKey[],
				outboundAuthConfigs,
				baseIntegrationARIs,
				filterDateRange,
			),

		[availableProducts, titleMatchValue, selectedEntities, outboundAuthConfigs, filterDateRange],
	);

	const { commonFilters, jiraFilters, confluenceFilters, thirdPartyFilters } = selectedFilters;

	const isEmpty =
		!commonFilters.range.lastModified &&
		selectedEntities.length === 0 &&
		confluenceFilters.spacesFilter.length === 0 &&
		!confluenceFilters.isVerified &&
		confluenceFilters.contributorsFilter.length === 0 &&
		confluenceFilters.ancestorIdsFilter.length === 0 &&
		confluenceFilters.range.length === 0 &&
		confluenceFilters.owners.length === 0 &&
		confluenceFilters.labelsFilter.length === 0 &&
		jiraFilters.issueFilter.assigneeARIs.length === 0 &&
		jiraFilters.issueFilter.projectARIs.length === 0 &&
		(!thirdPartyFilters.range || thirdPartyFilters?.range?.length === 0) &&
		!thirdPartyFilters.titleMatchOnly &&
		thirdPartyFilters.subtypes.length === 0;

	return {
		selectedFilters,
		unselectedProductsThirdPartyFilters,
		selectedEntities,
		entitiesForResultsCountQuery,
		entities: entitiesForQuery,
		hasFiltersSelected: !isEmpty,
	};
};
