import { fg } from '@atlaskit/platform-feature-flags';
import { type EntityATI } from '@atlassian/search-client';

import { ThirdPartyConfigs } from '../../constants/3p-product-configs';
import {
	CONFLUENCE_ANSWER_ENTITY_ATI,
	CONFLUENCE_CALENDAR_ENTITY_ATI,
	CONFLUENCE_QUESTION_ENTITY_ATI,
	CONFLUENCE_SPACE_CALENDAR_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_CALENDAR_EVENT_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_CONVERSATION_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_DESIGN_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_MESSAGE_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_VIDEO_ENTITY_ATI,
	DEFAULT_CLOUD_GRAPH_WORK_ITEM_ENTITY_ATI,
	DEFAULT_MERCURY_ENTITY_ATI,
	JIRA_ISSUE_ENTITY_ATI,
	JIRA_PROJECT_ENTITY_ATI,
} from '../../constants/entities';
import {
	allTypeFilterValueKeys,
	type TypeFilterMapping,
	TypeFilterTWGNounMapping,
	TypeFilterValueKey,
	type UniversalTypeFilterMapping,
} from '../../constants/filters';
import { ProductKeys, type ProductKeys1P } from '../../constants/products';

export const oldTypeMapping: UniversalTypeFilterMapping = {
	[TypeFilterValueKey.Document]: {
		products: [
			ProductKeys.Confluence,
			ProductKeys.Google,
			ProductKeys.Sharepoint,
			ProductKeys.OneDrive,
			ProductKeys.Dropbox,
			ProductKeys.Airtable,
			ProductKeys.Smartsheet,
			ProductKeys.Notion,
			ProductKeys.Docusign,
			ProductKeys.Monday,
			ProductKeys.Box,
		],
		entities: {
			confluence: [
				'ati:cloud:confluence:blogpost',
				'ati:cloud:confluence:page',
				'ati:cloud:confluence:attachment',
			],
			google: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
			onedrive: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
			sharepoint: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
			dropbox: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
			airtable: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
			smartsheet: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
			notion: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
			docusign: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
			monday: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
			box: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
		},
		subCategories: [TypeFilterValueKey.Document],
	},
	[TypeFilterValueKey.Spreadsheet]: {
		products: [
			ProductKeys.Google,
			ProductKeys.Sharepoint,
			ProductKeys.OneDrive,
			ProductKeys.Dropbox,
			ProductKeys.Box,
		],
		entities: {
			google: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
			onedrive: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
			sharepoint: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
			dropbox: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
			box: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
		},
		subCategories: [TypeFilterValueKey.Spreadsheet],
	},
	[TypeFilterValueKey.Presentation]: {
		products: [
			ProductKeys.Google,
			ProductKeys.Sharepoint,
			ProductKeys.OneDrive,
			ProductKeys.Dropbox,
			ProductKeys.Box,
		],
		entities: {
			google: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
			onedrive: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
			sharepoint: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
			dropbox: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
			box: DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
		},
		subCategories: [TypeFilterValueKey.Presentation],
	},
	[TypeFilterValueKey.Repository]: {
		products: [
			ProductKeys.Bitbucket,
			ProductKeys.Github,
			ProductKeys.AzureDevops,
			ProductKeys.Gitlab,
		],
		entities: {
			github: ['ati:cloud:graph:repository'],
			bitbucket: ['ati:cloud:graph:repository'],
			'azure-devops': ['ati:cloud:graph:repository'],
			gitlab: ['ati:cloud:graph:repository'],
		},
		subCategories: [],
	},
	[TypeFilterValueKey.PullRequest]: {
		products: [
			ProductKeys.Bitbucket,
			ProductKeys.Github,
			ProductKeys.AzureDevops,
			ProductKeys.Gitlab,
		],
		entities: {
			github: ['ati:cloud:graph:pull-request'],
			bitbucket: ['ati:cloud:graph:pull-request'],
			'azure-devops': ['ati:cloud:graph:pull-request'],
			gitlab: ['ati:cloud:graph:pull-request'],
		},
		subCategories: [],
	},
	[TypeFilterValueKey.Branch]: {
		products: [
			ProductKeys.Bitbucket,
			ProductKeys.Github,
			ProductKeys.AzureDevops,
			ProductKeys.Gitlab,
		],
		entities: {
			github: ['ati:cloud:graph:branch'],
			bitbucket: ['ati:cloud:graph:branch'],
			'azure-devops': ['ati:cloud:graph:branch'],
			gitlab: ['ati:cloud:graph:branch'],
		},
		subCategories: [],
	},
	[TypeFilterValueKey.Commit]: {
		products: [
			ProductKeys.Bitbucket,
			ProductKeys.Github,
			ProductKeys.AzureDevops,
			ProductKeys.Gitlab,
		],
		entities: {
			github: ['ati:cloud:graph:commit'],
			bitbucket: ['ati:cloud:graph:commit'],
			'azure-devops': ['ati:cloud:graph:commit'],
			gitlab: ['ati:cloud:graph:commit'],
		},
		subCategories: [],
	},
	[TypeFilterValueKey.WebPage]: {
		products: [ProductKeys.AtlassianWebCrawler],
		entities: {
			'atlassian-web-crawler': ['ati:cloud:graph:document'],
		},
		subCategories: [TypeFilterValueKey.WebPage],
	},
	[TypeFilterValueKey.Conversation]: {
		products: [ProductKeys.Slack, ProductKeys.Teams],
		entities: {
			slack: DEFAULT_CLOUD_GRAPH_CONVERSATION_ENTITY_ATI,
			teams: DEFAULT_CLOUD_GRAPH_CONVERSATION_ENTITY_ATI,
		},
		subCategories: [],
	},
	[TypeFilterValueKey.Message]: {
		products: [ProductKeys.Slack, ProductKeys.Teams],
		entities: {
			slack: DEFAULT_CLOUD_GRAPH_MESSAGE_ENTITY_ATI,
			teams: DEFAULT_CLOUD_GRAPH_MESSAGE_ENTITY_ATI,
		},
		subCategories: [],
	},
	[TypeFilterValueKey.Video]: {
		products: [ProductKeys.Loom],
		entities: {
			loom: DEFAULT_CLOUD_GRAPH_VIDEO_ENTITY_ATI,
		},
		subCategories: [],
	},
	[TypeFilterValueKey.Design]: {
		products: [ProductKeys.Figma, ProductKeys.Miro, ProductKeys.Lucid, ProductKeys.Canva],
		entities: {
			figma: DEFAULT_CLOUD_GRAPH_DESIGN_ENTITY_ATI,
			miro: DEFAULT_CLOUD_GRAPH_DESIGN_ENTITY_ATI,
			lucid: DEFAULT_CLOUD_GRAPH_DESIGN_ENTITY_ATI,
			canva: DEFAULT_CLOUD_GRAPH_DESIGN_ENTITY_ATI,
		},
		subCategories: ['file'],
	},
	[TypeFilterValueKey.Page]: {
		products: [ProductKeys.Confluence, ProductKeys.ConfluenceDC],
		entities: {
			confluence: ['ati:cloud:confluence:page'],
			'confluence-dc': DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
		},
		subCategories: [TypeFilterValueKey.Page],
	},
	[TypeFilterValueKey.Blogpost]: {
		products: [ProductKeys.Confluence, ProductKeys.ConfluenceDC],
		entities: {
			confluence: ['ati:cloud:confluence:blogpost'],
			'confluence-dc': DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
		},
		subCategories: [TypeFilterValueKey.Blogpost],
	},
	[TypeFilterValueKey.Attachment]: {
		products: [ProductKeys.Confluence],
		entities: {
			confluence: ['ati:cloud:confluence:attachment'],
		},
		subCategories: [],
	},
	[TypeFilterValueKey.Space]: {
		products: [ProductKeys.Confluence, ProductKeys.ConfluenceDC],
		entities: {
			confluence: ['ati:cloud:confluence:space'],
			'confluence-dc': DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI,
		},
		subCategories: [TypeFilterValueKey.Space],
	},
	[TypeFilterValueKey.Comment]: {
		products: [ProductKeys.Confluence],
		entities: {
			confluence: ['ati:cloud:confluence:comment'],
		},
		subCategories: [],
	},
	[TypeFilterValueKey.Whiteboard]: {
		products: [ProductKeys.Confluence],
		entities: {
			confluence: ['ati:cloud:confluence:whiteboard'],
		},
		subCategories: [],
	},
	[TypeFilterValueKey.Database]: {
		products: [ProductKeys.Confluence],
		entities: {
			confluence: ['ati:cloud:confluence:database'],
		},
		subCategories: [],
	},
	[TypeFilterValueKey.Embed]: {
		products: [ProductKeys.Confluence],
		entities: {
			confluence: ['ati:cloud:confluence:embed'],
		},
		subCategories: [],
	},
	[TypeFilterValueKey.Folder]: {
		products: [ProductKeys.Confluence],
		entities: {
			confluence: ['ati:cloud:confluence:folder'],
		},
		subCategories: [],
	},
	[TypeFilterValueKey.Calendar]: {
		products: [
			ProductKeys.Confluence,
			// TODO: >>>> QS-5465: Uncomment when calendar nouns are ready to be ingested
			// ProductKeys.GoogleCalendar,
			ProductKeys.OutlookCalendar,
		],
		entities: {
			confluence: [CONFLUENCE_CALENDAR_ENTITY_ATI],
			// 'google-calendar': DEFAULT_CLOUD_GRAPH_CALENDAR_EVENT_ENTITY_ATI,
			'outlook-calendar': DEFAULT_CLOUD_GRAPH_CALENDAR_EVENT_ENTITY_ATI,
		},
		subCategories: [],
	},
	[TypeFilterValueKey.SpaceCalendar]: {
		products: [ProductKeys.Confluence],
		entities: {
			confluence: [CONFLUENCE_SPACE_CALENDAR_ENTITY_ATI],
		},
		subCategories: [],
	},
	[TypeFilterValueKey.Question]: {
		products: [ProductKeys.Confluence],
		entities: {
			confluence: [CONFLUENCE_QUESTION_ENTITY_ATI],
		},
		subCategories: [],
	},
	[TypeFilterValueKey.Answer]: {
		products: [ProductKeys.Confluence],
		entities: {
			confluence: [CONFLUENCE_ANSWER_ENTITY_ATI],
		},
		subCategories: [],
	},
	[TypeFilterValueKey.Issue]: {
		products: [
			ProductKeys.Jira,
			ProductKeys.JiraProductDiscovery,
			ProductKeys.JiraServiceManagement,
		],
		entities: {
			jira: [JIRA_ISSUE_ENTITY_ATI],
			[ProductKeys.JiraProductDiscovery]: [JIRA_ISSUE_ENTITY_ATI],
			[ProductKeys.JiraServiceManagement]: [JIRA_ISSUE_ENTITY_ATI],
		},
		subCategories: [],
	},
	[TypeFilterValueKey.Component]: {
		products: [ProductKeys.Compass],
		entities: {
			compass: ['ati:cloud:compass:component'],
		},
		subCategories: [],
	},
	[TypeFilterValueKey.Card]: {
		products: [ProductKeys.Trello],
		entities: {
			trello: ['ati:cloud:trello:card'],
		},
		subCategories: [],
	},
	[TypeFilterValueKey.Project]: {
		products: [
			ProductKeys.Jira,
			ProductKeys.JiraProductDiscovery,
			ProductKeys.JiraServiceManagement,
			ProductKeys.Atlas,
		],
		entities: {
			jira: [JIRA_PROJECT_ENTITY_ATI],
			[ProductKeys.JiraProductDiscovery]: [JIRA_PROJECT_ENTITY_ATI],
			[ProductKeys.JiraServiceManagement]: [JIRA_PROJECT_ENTITY_ATI],
			townsquare: ['ati:cloud:townsquare:project'],
		},
		subCategories: [],
	},
	[TypeFilterValueKey.Goal]: {
		products: [ProductKeys.Atlas],
		entities: {
			townsquare: ['ati:cloud:townsquare:goal'],
		},
		subCategories: [],
	},
	[TypeFilterValueKey.Tag]: {
		products: [ProductKeys.Atlas],
		entities: {
			townsquare: ['ati:cloud:townsquare:tag'],
		},
		subCategories: [],
	},
	[TypeFilterValueKey.WorkItem]: {
		products: [ProductKeys.Asana, ProductKeys.Zendesk],
		entities: {
			asana: DEFAULT_CLOUD_GRAPH_WORK_ITEM_ENTITY_ATI,
			zendesk: DEFAULT_CLOUD_GRAPH_WORK_ITEM_ENTITY_ATI,
		},
		subCategories: [],
	},
	[TypeFilterValueKey.Incident]: {
		products: [ProductKeys.JiraServiceManagement],
		entities: {
			[ProductKeys.JiraServiceManagement]: [JIRA_ISSUE_ENTITY_ATI],
		},
		subCategories: [],
	},
	[TypeFilterValueKey.FocusArea]: {
		products: [ProductKeys.Focus],
		entities: {
			mercury: DEFAULT_MERCURY_ENTITY_ATI,
		},
		subCategories: [],
	},
};

export type TypeFilterProductMapping1P = Map<TypeFilterValueKey, EntityATI[]>;
/**
 * A mapping between 1P Products
 * - ProductKey1P e.g. 'confluence'
 *
 * UI values for the type filter
 * - TypeFilterKey e.g. 'Spreadsheet'
 *
 * and entities to request from Aggregator
 *
 * - ATIString[] e.g. ['ati:cloud:confluence:blogpost']
 */
export const typeFilter1PMapping = new Map<ProductKeys1P, TypeFilterProductMapping1P>([
	[
		ProductKeys.Confluence,
		new Map<TypeFilterValueKey, EntityATI[]>([
			[
				TypeFilterValueKey.Document,
				[
					'ati:cloud:confluence:blogpost',
					'ati:cloud:confluence:page',
					'ati:cloud:confluence:attachment',
				],
			],
			[TypeFilterValueKey.Page, ['ati:cloud:confluence:page']],
			[TypeFilterValueKey.Blogpost, ['ati:cloud:confluence:blogpost']],
			[TypeFilterValueKey.Attachment, ['ati:cloud:confluence:attachment']],
			[TypeFilterValueKey.Space, ['ati:cloud:confluence:space']],
			[TypeFilterValueKey.Comment, ['ati:cloud:confluence:comment']],
			[TypeFilterValueKey.Whiteboard, ['ati:cloud:confluence:whiteboard']],
			[TypeFilterValueKey.Database, ['ati:cloud:confluence:database']],
			[TypeFilterValueKey.Embed, ['ati:cloud:confluence:embed']],
			[TypeFilterValueKey.Folder, ['ati:cloud:confluence:folder']],
			[TypeFilterValueKey.Calendar, [CONFLUENCE_CALENDAR_ENTITY_ATI]],
			[TypeFilterValueKey.SpaceCalendar, [CONFLUENCE_SPACE_CALENDAR_ENTITY_ATI]],
			[TypeFilterValueKey.Question, [CONFLUENCE_QUESTION_ENTITY_ATI]],
			[TypeFilterValueKey.Answer, [CONFLUENCE_ANSWER_ENTITY_ATI]],
		]),
	],
	[
		ProductKeys.Jira,
		new Map<TypeFilterValueKey, EntityATI[]>([
			[TypeFilterValueKey.Issue, [JIRA_ISSUE_ENTITY_ATI]],
			[TypeFilterValueKey.Project, [JIRA_PROJECT_ENTITY_ATI]],
		]),
	],
	[
		ProductKeys.JiraProductDiscovery,
		new Map<TypeFilterValueKey, EntityATI[]>([
			[TypeFilterValueKey.Issue, [JIRA_ISSUE_ENTITY_ATI]],
			[TypeFilterValueKey.Project, [JIRA_PROJECT_ENTITY_ATI]],
		]),
	],
	[
		ProductKeys.JiraServiceManagement,
		new Map<TypeFilterValueKey, EntityATI[]>([
			[TypeFilterValueKey.Issue, [JIRA_ISSUE_ENTITY_ATI]],
			[TypeFilterValueKey.Project, [JIRA_PROJECT_ENTITY_ATI]],
			[TypeFilterValueKey.Incident, [JIRA_ISSUE_ENTITY_ATI]],
		]),
	],
	[
		ProductKeys.Compass,
		new Map<TypeFilterValueKey, EntityATI[]>([
			[TypeFilterValueKey.Component, ['ati:cloud:compass:component']],
		]),
	],
	[
		ProductKeys.Focus,
		new Map<TypeFilterValueKey, EntityATI[]>([
			[TypeFilterValueKey.FocusArea, ['ati:cloud:mercury:focus-area']],
		]),
	],
	[
		ProductKeys.Trello,
		new Map<TypeFilterValueKey, EntityATI[]>([
			[TypeFilterValueKey.Card, ['ati:cloud:trello:card']],
		]),
	],
	[
		ProductKeys.Atlas,
		new Map<TypeFilterValueKey, EntityATI[]>([
			[TypeFilterValueKey.Project, ['ati:cloud:townsquare:project']],
			[TypeFilterValueKey.Goal, ['ati:cloud:townsquare:goal']],
			[TypeFilterValueKey.Tag, ['ati:cloud:townsquare:tag']],
		]),
	],
	[
		ProductKeys.Bitbucket,
		new Map<TypeFilterValueKey, EntityATI[]>([
			[TypeFilterValueKey.Commit, ['ati:cloud:graph:commit']],
			[TypeFilterValueKey.PullRequest, ['ati:cloud:graph:pull-request']],
			[TypeFilterValueKey.Repository, ['ati:cloud:graph:repository']],
			[TypeFilterValueKey.Branch, ['ati:cloud:graph:branch']],
		]),
	],
]);

const getNewTypeMapping: () => UniversalTypeFilterMapping = () => {
	const mappings: UniversalTypeFilterMapping = Object.fromEntries(
		allTypeFilterValueKeys.map((TypeFilterValueKey) => {
			const baseMapping: TypeFilterMapping = {
				entities: {},
				products: [],
				subCategories: [],
			};
			return [TypeFilterValueKey as TypeFilterValueKey, baseMapping];
		}),
	) as UniversalTypeFilterMapping;
	// Add all the 1P products
	typeFilter1PMapping.forEach((_, productKey1P) => {
		typeFilter1PMapping.get(productKey1P)?.forEach((_, typeFilterValueKey) => {
			mappings[typeFilterValueKey].products.push(productKey1P);
			mappings[typeFilterValueKey].entities[productKey1P] = typeFilter1PMapping
				.get(productKey1P)
				?.get(typeFilterValueKey);
		});
	});
	// Add all the 3P products
	ThirdPartyConfigs.forEach((_, productKey3P) => {
		const typeFilterValues3P = ThirdPartyConfigs.get(productKey3P)?.typeFilterValues || [];
		typeFilterValues3P.forEach((typeFilterValueKey) => {
			mappings[typeFilterValueKey].products.push(productKey3P);
			mappings[typeFilterValueKey].entities[productKey3P] =
				TypeFilterTWGNounMapping.get(typeFilterValueKey)?.entities3P;
			mappings[typeFilterValueKey].subCategories =
				TypeFilterTWGNounMapping.get(typeFilterValueKey)?.subTypes3P || [];
		});
	});

	return mappings;
};

export const featureGateNotEnabledTypes: () => any[] = () =>
	[[fg('enable_jsm_incident_type'), 'incident'] as const].flatMap(([isTypeEnabled, type]) =>
		isTypeEnabled ? [] : type,
	);

// eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
export const getTypeMapping: () => UniversalTypeFilterMapping = () =>
	fg('rovo-full-page-search-3p-static-config') ? getNewTypeMapping() : oldTypeMapping;

export { TypeFilterValueKey };
