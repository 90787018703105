import { fg } from '@atlaskit/platform-feature-flags';

import { TypeFilterValueKey } from '../../filters';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { BoxIcon } from './icon';

export const boxConfig: ThirdPartyConfig = {
	displayName: 'Box',
	id: 'box',
	resourceType: 'box-connector',
	icon: RovoLogoFromGlyph(BoxIcon, 'Box'),
	integrationAri: 'ari:cloud:platform::integration/box',
	typeFilterValues: [
		TypeFilterValueKey.Document,
		TypeFilterValueKey.Spreadsheet,
		TypeFilterValueKey.Presentation,
	],
	filters: ['lastUpdated', 'onlyTitleMatch'],
	requiresOauth: true,
	// eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
	isEnabled: () => fg('enable_3p_search_box'),
};
