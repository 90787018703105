import { z } from 'zod';

import { productKeySchema } from './query-params';

const outboundAuthConfigSchema = z.object({
	url: z.string(),
	userNeedsOAuth: z.boolean(),
});
export type OutboundAuthConfig = z.infer<typeof outboundAuthConfigSchema>;

export const outboundAuthConfigsSchema = z
	.record(productKeySchema, outboundAuthConfigSchema)
	.or(z.record(z.never())); // Allow for empty objects = {}
export type OutboundAuthConfigsType = z.infer<typeof outboundAuthConfigsSchema>;
