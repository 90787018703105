import { fg } from '@atlaskit/platform-feature-flags';

import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { OutlookEmailIcon } from './icon';

export const outlookEmail: ThirdPartyConfig = {
	displayName: 'Outlook Email',
	id: 'outlook-email',
	resourceType: 'outlook-email-connector',
	icon: RovoLogoFromGlyph(OutlookEmailIcon, 'Outlook Email'),
	integrationAri: 'ari:cloud:platform::integration/outlook-email',
	requiresOauth: true,
	typeFilterValues: [],
	filters: ['lastUpdated'],
	// eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
	isEnabled: () => fg('enable_3p_search_gmail'),
};
