import React from 'react';

import { FormattedMessage } from 'react-intl-next';

import { TypeFilterValueKey } from '../../filters';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { WebDomainIcon } from './icon';
import { messages } from './messages';
import { WebDomainSearchResultIcon } from './search-result-icon';

export const atlassianWebCrawlerConfig: ThirdPartyConfig = {
	displayName: <FormattedMessage {...messages['atlassian-web-crawler']} />,
	id: 'atlassian-web-crawler',
	resourceType: 'webcrawler-connector',
	icon: RovoLogoFromGlyph(WebDomainIcon, 'Atlassian Web Crawler'),
	searchResultIcon: RovoLogoFromGlyph(WebDomainSearchResultIcon, 'Atlassian Web Crawler'),
	integrationAri: 'ari:cloud:platform::integration/atlassian-web-crawler',
	typeFilterValues: [TypeFilterValueKey.WebPage],
	requiresOauth: false,
	isEnabled: () => true,
};
