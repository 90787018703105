export const ExternalMessagePartial = `
... on ExternalMessage {
    lastUpdated
    description
    attachments {
	    title
        mimeType
        thumbnailUrl
        url
    }
    createdBy {
        thirdPartyUser {
            id
            accountId
            externalId
            name
            nickname
            email
			picture
        }
        user {
            id
            name
        }
    }
    container @optIn(to: "ExternalDataDepotQueryV2") {
        ... on ExternalConversation {
            id
            displayName
            memberCount
            membershipType
            type
            members {
                thirdPartyUser {
                    id
                    name
                }
            }
        }
    }
    associatedWith {
        edges {
            node {
                id
                createdBy {
                    thirdPartyUser {
                        id
                        accountId
                        externalId
                        name
                        email
                    }
                }
                entity {
					__typename
					... on ExternalConversation {
						id
						displayName
						channelId:externalId
					}
					... on ThirdPartyUser {
                        id
                        email
						externalId
                        name
                        nickname
                        picture
                    }
                }
            }
        }
    }
}
`;
