import { TypeFilterValueKey } from '../../filters';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { TeamsIcon } from './icon';
import { TeamsSearchResultIcon } from './search-result-icon';

export const teamsConfig: ThirdPartyConfig = {
	displayName: 'Teams',
	id: 'teams',
	resourceType: 'microsoft-teams-connector',
	icon: RovoLogoFromGlyph(TeamsIcon, 'Teams'),
	searchResultIcon: RovoLogoFromGlyph(TeamsSearchResultIcon, 'Teams'),
	integrationAri: 'ari:cloud:platform::integration/microsoft',
	typeFilterValues: [TypeFilterValueKey.Conversation, TypeFilterValueKey.Message],
	filters: ['lastUpdated'],
	requiresOauth: true,
	isEnabled: () => true,
};
