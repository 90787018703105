import { TypeFilterValueKey } from '../../filters';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { ZendeskFilterIcon } from './icon';
import { ZendeskIcon } from './search-result-icon';

export const zendeskConfig: ThirdPartyConfig = {
	displayName: 'Zendesk',
	id: 'zendesk',
	icon: RovoLogoFromGlyph(ZendeskFilterIcon, 'Zendesk'),
	resourceType: 'zendesk-connector',
	searchResultIcon: RovoLogoFromGlyph(ZendeskIcon, 'Zendesk'),
	integrationAri: 'ari:cloud:platform::integration/zendesk',
	typeFilterValues: [TypeFilterValueKey.WorkItem],
	requiresOauth: true,
	isEnabled: () => false,
};
