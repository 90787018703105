import React, { type PropsWithChildren, useCallback } from 'react';

import {
	getBackendExperimentsWithOverrides,
	getFrontendExperiment,
	STATSIG_FULL_PAGE_SEARCH_LAYER,
} from '@atlassian/search-experiment';

import {
	allProductKeys,
	type PrimaryProductKey,
	ProductKeys,
} from '../../../../common/constants/products';
import { outboundAuthConfigsSchema } from '../../../../common/constants/schemas/outbound-auth-config';
import { type SearchQueryStringParamsRaw } from '../../../../common/constants/schemas/query-params';
import { type UserDetails } from '../../../../common/constants/types';
import { useFetchConfig, type UseFetchConfigProps } from '../../../config';
import { useLogException } from '../../../error-boundary';
import { BootstrapStoreContainer, type Features, type TState } from '../index';
import { getAccessibleProducts } from '../utils';

export type BootstrapContainerProps = PropsWithChildren<
	{
		/**
		 * Passing this will override the behaviour of bootstrapping in all product mode.
		 * The UI will only show the products which have been passed PROVIDED the user has access to them.
		 *
		 * NOTE: QS-4325: The `products` parameter is deprecated as we are removing it as a
		 * prop from the root <SearchPage/>.
		 */
		products?: ProductKeys[];

		/**
		 * The product the search page will be rendered in.
		 */
		primaryProduct: PrimaryProductKey;

		/**
		 * This restricts the searches only to the cloudId that was passedin.
		 * This is a Jira/Confluence term and unfortunately most of the Atlassian products continue to be tied to a site.
		 *
		 * This propertly is useless for Trello or Bitbucket which are Workspace scoped.
		 *
		 * Not passing this property would bootstrap the UI in the site on which the user is most active
		 */
		cloudId?: string;

		/**
		 * The absolute url to the Atlassian Graphql Gateway. If not passed in the relative URL would be used.
		 */
		aggAbsoluteUrl?: string;

		/**
		 * Details of the current user.
		 */
		user?: UserDetails;

		/**
		 * Extra attributes to be attached to analytics events that are fired from within Full Page Search
		 */
		analyticsAttributes?: Record<string, any>;

		SAINHostName?: string;

		orgId?: string;

		queryParams?: Record<string, any>;

		/**
		 * Features that are enabled/disabled for the search page
		 */
		features?: Features;

		/**
		 * A unique identifier for the search session. If not passed, a random UUID will be generated.
		 */
		searchSessionId?: string;

		isAdminHubAIEnabled?: boolean;

		/**
		 * When enabled, there will only be one input to search with (located in the navigation bar).
		 * When disabled, there will be two inputs to search with: 1 in the navigation bar and another on the search page.
		 */
		isNav4Enabled?: boolean;

		callbacks?: {
			/**
			 * A callback that is invoked when the query parameters in the URL need to be updated.
			 * The consuming product can implement the specific steps to be done when this callback is invoked.
			 *
			 * @param queryParamObject An object which is key:value map of things that should be set as the query parameter.
			 */
			updateQueryParameters?: (queryParamObject: Partial<SearchQueryStringParamsRaw>) => void;

			/**
			 * @returns the space IDs for the given space keys. NOTE: these should be the space IDs, not ARIs.
			 * @example getSpaceIdsFromSpaceKeys(["STATUS"]); // Promise<["123454321"]>
			 **/
			getSpaceIdsFromSpaceKeys?: (spaceKeys: string[]) => Promise<string[]>;
		};
	} & Pick<
		UseFetchConfigProps,
		'configFetchAbsoluteUrl' | 'thirdPartyAbsoluteUrl' | 'thirdPartyDisabled' | 'aggregatorHostName'
	>
>;

export const BootstrapStoreProvider = ({
	children,
	...props
}: PropsWithChildren<BootstrapContainerProps>) => {
	const {
		cloudId,
		configFetchAbsoluteUrl,
		user,
		thirdPartyAbsoluteUrl,
		thirdPartyDisabled,
		primaryProduct,
		aggAbsoluteUrl,
		SAINHostName,
		aggregatorHostName,
		orgId,
		analyticsAttributes,
		products,
		queryParams,
		features,
		searchSessionId,
		isAdminHubAIEnabled,
		isNav4Enabled,
		callbacks,
	} = props;

	const {
		accessibleProducts,
		outboundAuthConfigs,
		firstCloudId,
		intercomHmac,
		isNounsAvailable,
		isRovoEnabled,
	} = useFetchConfig({
		cloudId,
		configFetchAbsoluteUrl,
		aggregatorHostName,
		userId: user?.id,
		thirdPartyAbsoluteUrl,
		thirdPartyDisabled,
		primaryProduct,
	});

	const onError = useLogException();

	const { backendExperiment, frontendExperiment } = getBackendExperimentsWithOverrides(
		features?.sideBySide?.experiments,
		STATSIG_FULL_PAGE_SEARCH_LAYER,
		onError,
	);

	const exampleFrontendExperiment = getFrontendExperiment({ abTestId: 'fe_search_experiment' });

	const getInitialProps = useCallback(() => {
		let storeContainerInitialProps: Partial<TState> = {
			cloudId: cloudId ?? firstCloudId,
			aggAbsoluteUrl,
			SAINHostName,
			aggregatorHostName,
			user,
			primaryProduct,
			orgId,
			analyticsAttributes,
			features,
			experimentConfig: {
				backendExperiment,
				frontendExperiments: {
					// the mutually exclusive frontend experiment
					...(frontendExperiment?.abTestId && {
						[frontendExperiment.abTestId]: frontendExperiment,
					}),
					// add other frontend experiments that can be run alongside backend experiments here
					[exampleFrontendExperiment.abTestId]: exampleFrontendExperiment,
				},
			},
			intercomHmac,
			isAdminHubAIEnabled,
			isNav4Enabled,
			isNounsAvailable,
			isRovoEnabled,
			products: getAccessibleProducts({
				supportedProducts: allProductKeys,
				products,
				accessibleProducts,
			}),
			allFilterExcludedProducts: [ProductKeys.Trello],
		};

		if (searchSessionId) {
			storeContainerInitialProps.searchSessionId = searchSessionId;
		}
		if (accessibleProducts) {
			const parsedOutboundAuth = outboundAuthConfigsSchema.safeParse(outboundAuthConfigs);
			if (parsedOutboundAuth.success) {
				storeContainerInitialProps.outboundAuthConfigs = outboundAuthConfigs;
			}
		}

		return storeContainerInitialProps;
	}, [
		cloudId,
		firstCloudId,
		aggAbsoluteUrl,
		SAINHostName,
		aggregatorHostName,
		user,
		primaryProduct,
		orgId,
		analyticsAttributes,
		features,
		backendExperiment,
		frontendExperiment,
		exampleFrontendExperiment,
		intercomHmac,
		isAdminHubAIEnabled,
		isNav4Enabled,
		isNounsAvailable,
		isRovoEnabled,
		products,
		accessibleProducts,
		searchSessionId,
		outboundAuthConfigs,
	]);

	return (
		<BootstrapStoreContainer
			initialProps={getInitialProps()}
			queryParams={queryParams}
			queryParamsCallback={callbacks?.updateQueryParameters}
			getSpaceIdsFromSpaceKeys={callbacks?.getSpaceIdsFromSpaceKeys}
		>
			{children}
		</BootstrapStoreContainer>
	);
};
