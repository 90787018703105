import { type EntityATI } from '@atlassian/search-client';

import {
	allTypeFilterValueKeys,
	type TypeFilterValueKey,
} from '../../../../../common/constants/filters';
import {
	products as productConfigs,
	type ProductKeys,
} from '../../../../../common/constants/products';
import { ContentType } from '../../../../../common/constants/schemas/query-params';
import { type OutboundAuthConfigsType } from '../../../../../common/types';
import {
	featureGateNotEnabledTypes,
	getTypeMapping,
} from '../../../../../common/utils/filters/constants';
import { type FetchedSelectFilterOption } from '../../../../filters/base-select-filter/types';
import { type CloudConfig } from '../../../../filters/types';

import { typeLabels } from './messages';

type FilterOption = FetchedSelectFilterOption & { products: ProductKeys[] };

const isProductEntitiesIncluded = (
	availableProducts: ProductKeys[],
	product: ProductKeys,
	outboundAuthConfigs: OutboundAuthConfigsType,
) => {
	if (!productConfigs[product]) {
		return false;
	} else if (productConfigs[product]?.is3P) {
		return availableProducts.includes(product) && !outboundAuthConfigs[product]?.userNeedsOAuth;
	} else {
		return availableProducts.includes(product);
	}
};

/**
 * Given the user's search input within the Type filter,
 * return the matching types to the user.
 */
export const lookupOptions = (query: string, config: CloudConfig): FetchedSelectFilterOption[] => {
	const allOptions = getAllTypeOptions(config);
	const matchingOptions = allOptions
		.filter((option) => lookupMatches(query, option.label))
		.map((option) => ({
			label: option.label,
			trackingKey: option.value,
			value: option.value,
			queryParamValue: option.queryParamValue,
			isDefault: option.isDefault,
		}));
	return matchingOptions;
};

/**
 * This function is used for populating the entities for the search request
 */
export const getEntitiesForQuery = (
	products: ProductKeys[],
	selectedTypes: TypeFilterValueKey[],
	outboundAuthConfigs: OutboundAuthConfigsType,
	availableProducts: ProductKeys[],
): {
	entitiesForQuery: EntityATI[];
	entitiesForResultsCountQuery: EntityATI[];
} => {
	const selectedEntities = new Set<EntityATI>();
	const unselectedEntities = new Set<EntityATI>();
	const types = selectedTypes.length === 0 ? allTypeFilterValueKeys : selectedTypes;
	const typeMapping = getTypeMapping();

	const otherProducts = availableProducts.filter((product) => !products.includes(product));
	const availableTypes = types.filter((type) => !featureGateNotEnabledTypes().includes(type));
	availableTypes.forEach((type) => {
		const productToEntityMap = typeMapping[type].entities;
		products.forEach((product) => {
			if (isProductEntitiesIncluded(products, product, outboundAuthConfigs)) {
				productToEntityMap[product]?.forEach((ati) => selectedEntities.add(ati));
			}
		});
		// collect unselected entities
		otherProducts.forEach((product) => {
			if (isProductEntitiesIncluded(otherProducts, product, outboundAuthConfigs)) {
				productToEntityMap[product]?.forEach((ati) => {
					if (!selectedEntities.has(ati)) {
						unselectedEntities.add(ati);
					}
				});
			}
		});
	});

	/// result count wants all entities for selected type
	const entitiesForResultsCountQuery = Array.from([
		...selectedEntities,
		...unselectedEntities,
	]).sort();

	// Do not show Atlas goal and tag results when no product is selected
	if (types.includes('project') && products.length > 1) {
		selectedEntities.delete('ati:cloud:townsquare:goal');
		selectedEntities.delete('ati:cloud:townsquare:tag');
	}

	const entitiesForQuery = Array.from(selectedEntities).sort();

	// Sorting helps a ton in testing
	return {
		entitiesForQuery,
		entitiesForResultsCountQuery,
	};
};

export const lookupMatches = (query: string, label: string): boolean => {
	return label.toLowerCase().includes(query.toLowerCase());
};

export const getAllTypeOptions = (config: CloudConfig): FilterOption[] => {
	const typeMapping = getTypeMapping();

	const allOption: FilterOption = {
		products: [],
		label: config.intl?.formatMessage(typeLabels['all']) || '',
		value: 'all',
		trackingKey: 'all',
		queryParamValue: ContentType['All'],
		isDefault: true,
	};

	const typeOptions: FilterOption[] = allTypeFilterValueKeys.map((type) => ({
		products: typeMapping[type].products,
		value: type,
		trackingKey: type,
		queryParamValue: type,
		isDefault: false,
		label: config.intl?.formatMessage(typeLabels[type]) || '',
	}));

	return [allOption, ...typeOptions];
};
