import { type ThirdPartyConfig } from '../schemas/3p-config';

import { airtableConfig } from './airtable';
import { asanaConfig } from './asana';
import { atlassianWebCrawlerConfig } from './atlassian-web-crawler';
import { azureDevopsConfig } from './azure-devops';
import { boxConfig } from './box';
import { canvaConfig } from './canva';
import { confluenceDCConfig } from './confluence-dc';
import { docusignConfig } from './docusign';
import { dropboxConfig } from './dropbox';
import { figmaConfig } from './figma';
import { githubConfig } from './github';
import { gitlabConfig } from './gitlab';
import { gmailConfig } from './gmail';
import { googleDriveConfig } from './google';
import { googleCalendarConfig } from './google-calendar';
import { loomConfig } from './loom';
import { lucidConfig } from './lucid';
import { miroConfig } from './miro';
import { mondayConfig } from './monday';
import { notionConfig } from './notion';
import { oneDriveConfig } from './onedrive';
import { outlookCalendarConfig } from './outlook-calendar';
import { outlookEmail } from './outlook-email';
import { sharepointConfig } from './sharepoint';
import { slackConfig } from './slack';
import { smartsheetConfig } from './smartsheet';
import { teamsConfig } from './teams';
import { zendeskConfig } from './zendesk';
// New 3P? Add it here

const ProductKeys3P = {
	AtlassianWebCrawler: 'atlassian-web-crawler',
	Figma: 'figma',
	Github: 'github',
	Google: 'google',
	Loom: 'loom',
	OneDrive: 'onedrive',
	Dropbox: 'dropbox',
	Slack: 'slack',
	Sharepoint: 'sharepoint',
	Teams: 'teams',
	GoogleCalendar: 'google-calendar',
	OutlookCalendar: 'outlook-calendar',
	OutlookEmail: 'outlook-email',
	Gmail: 'gmail',
	Asana: 'asana',
	Zendesk: 'zendesk',
	Airtable: 'airtable',
	Smartsheet: 'smartsheet',
	Notion: 'notion',
	Miro: 'miro',
	Docusign: 'docusign',
	Lucid: 'lucid',
	Monday: 'monday',
	AzureDevops: 'azure-devops',
	Gitlab: 'gitlab',
	ConfluenceDC: 'confluence-dc',
	Canva: 'canva',
	Box: 'box',
	// New 3P? Add it here
} as const;

export const ThirdPartyConfigs = new Map<ProductKeys3P, ThirdPartyConfig>([
	[ProductKeys3P.AtlassianWebCrawler, atlassianWebCrawlerConfig],
	[ProductKeys3P.Figma, figmaConfig],
	[ProductKeys3P.Github, githubConfig],
	[ProductKeys3P.Google, googleDriveConfig],
	[ProductKeys3P.Sharepoint, sharepointConfig],
	[ProductKeys3P.Loom, loomConfig],
	[ProductKeys3P.OneDrive, oneDriveConfig],
	[ProductKeys3P.Dropbox, dropboxConfig],
	[ProductKeys3P.Slack, slackConfig],
	[ProductKeys3P.Teams, teamsConfig],
	[ProductKeys3P.GoogleCalendar, googleCalendarConfig],
	[ProductKeys3P.Gmail, gmailConfig],
	[ProductKeys3P.OutlookCalendar, outlookCalendarConfig],
	[ProductKeys3P.Asana, asanaConfig],
	[ProductKeys3P.Zendesk, zendeskConfig],
	[ProductKeys3P.Airtable, airtableConfig],
	[ProductKeys3P.Smartsheet, smartsheetConfig],
	[ProductKeys3P.Notion, notionConfig],
	[ProductKeys3P.Miro, miroConfig],
	[ProductKeys3P.Docusign, docusignConfig],
	[ProductKeys3P.Lucid, lucidConfig],
	[ProductKeys3P.Monday, mondayConfig],
	[ProductKeys3P.AzureDevops, azureDevopsConfig],
	[ProductKeys3P.Gitlab, gitlabConfig],
	[ProductKeys3P.ConfluenceDC, confluenceDCConfig],
	[ProductKeys3P.Canva, canvaConfig],
	[ProductKeys3P.Box, boxConfig],
	[ProductKeys3P.OutlookEmail, outlookEmail],
	// New 3P? Add it here
]);

type ProductKeys3P = (typeof ProductKeys3P)[keyof typeof ProductKeys3P];

const ProductsOrder3P: ProductKeys3P[] = [
	// // Loom is actually a 1P so goes to the top
	ProductKeys3P.Loom,

	// Eventually this might be "smart"
	// For now this static order isn't strongly defined
	ProductKeys3P.Google,
	ProductKeys3P.Sharepoint,
	ProductKeys3P.OneDrive,
	ProductKeys3P.Dropbox,
	ProductKeys3P.Slack,
	ProductKeys3P.Teams,
	ProductKeys3P.Github,
	ProductKeys3P.Figma,
	ProductKeys3P.GoogleCalendar,
	ProductKeys3P.Gmail,
	ProductKeys3P.OutlookCalendar,
	ProductKeys3P.OutlookEmail,
	ProductKeys3P.Airtable,
	ProductKeys3P.Smartsheet,
	ProductKeys3P.Asana,
	ProductKeys3P.Zendesk,
	ProductKeys3P.Miro,
	ProductKeys3P.Docusign,
	ProductKeys3P.Lucid,
	ProductKeys3P.Monday,
	ProductKeys3P.AzureDevops,
	ProductKeys3P.Gitlab,
	ProductKeys3P.ConfluenceDC,
	ProductKeys3P.Canva,
	ProductKeys3P.Box,
	// New 3P? Add it here

	// Always last
	ProductKeys3P.AtlassianWebCrawler,
];

/**
 * DO NOT import this directly
 *
 * Please use `import { ProductKeys3P } from 'src/common/constants/products.tsx'`
 */
export const DO_NOT_IMPORT_ProductKeys3P = ProductKeys3P;

/**
 * DO NOT import this directly
 *
 * Please use `import { ProductsOrder } from 'src/common/constants/products.tsx'`
 */
export const DO_NOT_IMPORT_ProductsOrder3P = ProductsOrder3P;
