import { ConfluenceIcon } from '@atlaskit/logo';

import { TypeFilterValueKey } from '../../filters';
import { RovoLogoFromGlyph, RovoLogoFromLogo } from '../../icons';
import { ConfluenceSearchResultIcon } from '../../icons/confluence-search';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

export const confluenceDCConfig: ThirdPartyConfig = {
	displayName: 'Confluence Data Center',
	id: 'confluence-dc',
	resourceType: 'confluence-dc-connector',
	icon: RovoLogoFromLogo(ConfluenceIcon, 'Confluence Data Center'),
	searchResultIcon: RovoLogoFromGlyph(ConfluenceSearchResultIcon, 'Confluence Data Center'),
	integrationAri: 'ari:cloud:platform::integration/confluence-dc',
	typeFilterValues: [
		TypeFilterValueKey.Space,
		TypeFilterValueKey.Page,
		TypeFilterValueKey.Blogpost,
	],
	filters: ['lastUpdated', 'onlyTitleMatch'],
	requiresOauth: true,
	isEnabled: () => false,
};
