import {
	clearOptions,
	deselectOptions,
	getOptions,
	getQueryParams,
	getState,
	getValue,
	loadInitialOptions,
	setLookupInput,
	setQueryParams,
} from '../base-select-filter';
import {
	type CreateSelectFilter,
	type SelectFilterDeps,
	type SelectFilterOption,
	type SelectFilterOptionChange,
	type SelectFilterState,
	type SelectFilterType,
} from '../base-select-filter/types';
import { type CloudConfig } from '../types';

export const createSingleSelectFilter = <ID extends string = string>(
	initial: CreateSelectFilter<ID>,
) => {
	const initialState = Object.freeze({
		id: initial.id,
		type: 'single-select',
		didMount: false,
		hasSelection: false,
		alwaysVisible: initial.alwaysVisible,
		universal: initial.universal,
		queryParams: {
			isLoading: false,
			options: [],
		},
		lookup: {
			isLoading: false,
			isActive: false,
			isDefined: !!initial.lookup,
			options: [],
			lookupResultOptions: [],
			input: '',
		},
		initialOptions: {
			options: [],
			isLoading: false,
		},
		options: [],
		selectedOptions: [],
		value: [],
		products: initial.products,
	} satisfies SelectFilterState<ID>);

	return {
		id: initial.id,
		type: initialState.type,
		queryParams: { key: initial.queryParams.key },
		products: initial.products,
		universal: initial.universal,
		customValueFields: initial.customValueFields,
		withState: (
			state: unknown, // To support polymorphic function calls from the Store
			config: CloudConfig,
		) =>
			SingleSelectFilter({
				initial,
				state: state as SelectFilterState<ID>, // This has to be cast back to the correct state
				config,
			}),
		getInitialState: (config: CloudConfig) =>
			SingleSelectFilter({ initial, state: initialState, config }),
		isCompatible: (filterState?: { id: string }): filterState is SelectFilterState<ID> => {
			return filterState?.id === initial.id;
		},
		isSupportCustomValue: false,
	};
};

export const SingleSelectFilter: SelectFilterType = ({ initial, state, config }) => {
	const filterState = Object.freeze({
		...state,
	});

	return {
		id: initial.id,
		type: filterState.type,
		getState: getState({ initial, state: filterState, config }),
		queryParams: {
			key: initial.queryParams.key,
			get: getQueryParams({ initial, state: filterState, config }),
			set: setQueryParams({ initial, state: filterState, config }),
		},
		loadInitialOptions: loadInitialOptions({ initial, state: filterState, config }),
		setOption: setOption({ initial, state: filterState, config }),
		clearOptions: clearOptions({ initial, state: filterState, config }),
		setLookupInput: setLookupInput({ initial, state: filterState, config }),
		getOptions: getOptions({ initial, state: filterState, config }),
		getValue: getValue({ initial, state: filterState, config }),
		clear: deselectOptions({ initial, state: filterState, config }),
	};
};

const updateOptionState =
	(props: {
		optionsToChange: SelectFilterOptionChange[];
		firstSelectedOption?: SelectFilterOptionChange;
	}) =>
	(option: SelectFilterOption) => {
		if (option.isDefault) {
			return {
				...option,
				isSelected: false,
			};
		}

		return {
			...option,
			isSelected: props.firstSelectedOption?.value === option.value,
		};
	};

export const setOption = <ID extends string>({ initial, state, config }: SelectFilterDeps<ID>) => {
	return (option: SelectFilterOptionChange | SelectFilterOptionChange[]) => {
		// Theoretically this should only ever give you one option to change
		const optionsToChange = Array.isArray(option) ? option : [option];
		const firstSelectedOption = optionsToChange.at(0);

		const isOptionAlreadySelected = state.options
			.filter((x) => x.isSelected)
			.find((x) => x.value === firstSelectedOption?.value);

		if (isOptionAlreadySelected) {
			// do nothing
			return SingleSelectFilter({
				initial: initial satisfies CreateSelectFilter<ID>,
				state,
				config,
			});
		}

		const queryParamOptions = state.queryParams.options.map(
			updateOptionState({
				optionsToChange,
				firstSelectedOption,
			}),
		);

		const initialOptions = state.initialOptions.options.map(
			updateOptionState({
				optionsToChange,
				firstSelectedOption,
			}),
		);

		const lookupOptions = [
			...state.lookup.options,
			// 'interacted' lookup options are options that a user made a lookup for and has either
			// selected or deselected an option from a lookup query
			// These 'interacted' options need to be persisted if a user wants to select them again
			...state.lookup.lookupResultOptions.flatMap((option) => {
				if (!optionsToChange.find((x) => x.value === option.value)) {
					return [];
				}

				return [option];
			}),
		].map(
			updateOptionState({
				optionsToChange,
				firstSelectedOption,
			}),
		);

		return SingleSelectFilter({
			initial: initial satisfies CreateSelectFilter<ID>,
			state: {
				...state,
				initialOptions: {
					...state.initialOptions,
					options: initialOptions,
				},
				queryParams: {
					...state.queryParams,
					options: queryParamOptions,
				},
				lookup: {
					...state.lookup,
					options: lookupOptions,
				},
			},
			config,
		});
	};
};
