import { universalTypeFilterId } from '../../../../common/constants/filters';

import { filter as assigneeFilter } from './assignee';
import { filter as contentFilter } from './content';
import { filter as contributorFilter } from './contributor';
import { filter as labelFilter } from './label';
import { filter as lastUpdatedFilter } from './last-updated';
import { filter as ownerFilter } from './owner';
import { filter as projectFilter } from './project';
import { filter as spaceFilter } from './space';
import { filter as titleMatchFilter } from './title-match-only';
import { filter as universalTypeFilter } from './universal-type';
import { filter as verifiedFilter } from './verified';

export const allFilters = Object.freeze({
	[lastUpdatedFilter.id]: lastUpdatedFilter,
	[contributorFilter.id]: contributorFilter,
	[spaceFilter.id]: spaceFilter,
	[verifiedFilter.id]: verifiedFilter,
	[assigneeFilter.id]: assigneeFilter,
	[projectFilter.id]: projectFilter,
	[universalTypeFilterId]: universalTypeFilter,
	[contentFilter.id]: contentFilter,
	[titleMatchFilter.id]: titleMatchFilter,
	[ownerFilter.id]: ownerFilter,
	[labelFilter.id]: labelFilter,
});

export {
	assigneeFilter,
	contentFilter,
	contributorFilter,
	lastUpdatedFilter,
	projectFilter,
	spaceFilter,
	verifiedFilter,
	titleMatchFilter,
	universalTypeFilter,
	ownerFilter,
	labelFilter,
};

export type AllFilterValues = (typeof allFilters)[keyof typeof allFilters];

export type AllFilterStates = ReturnType<
	ReturnType<AllFilterValues['getInitialState']>['getState']
>;

export type Filters = Record<string, AllFilterStates | undefined>;

export type AllFilterKeys = keyof typeof allFilters;
