import { fg } from '@atlaskit/platform-feature-flags';

import {
	ProductKeys,
	type ProductKeys1P,
	type ProductKeys3P,
} from '../../../../../common/constants/products';
import { LastModified } from '../../../../../common/constants/schemas/query-params';
import {
	getInvalidDateRange,
	getProducts3PFromConfigForFilter,
} from '../../../../../common/utils/filters';
import { type FetchedSelectFilterOption } from '../../../../filters/base-select-filter/types';
import { createSingleWithCustomOptionSelectFilter } from '../../../../filters/single-select-with-custom-option-filter';

import { lastUpdatedLabels } from './messages';

const LAST_UPDATED_VALUES = [
	'today',
	'yesterday',
	'past7Days',
	'past30Days',
	'pastYear',
	'custom',
] as const;

type LastUpdatedType = (typeof LAST_UPDATED_VALUES)[number];

const sortCompareFn = (a: FetchedSelectFilterOption, b: FetchedSelectFilterOption) => {
	return (
		LAST_UPDATED_VALUES.indexOf(a.value as LastUpdatedType) -
		LAST_UPDATED_VALUES.indexOf(b.value as LastUpdatedType)
	);
};
const isIsoDate = (str?: string) => {
	if (!str) {
		return false;
	}
	if (!/\d{4}-\d{2}-\d{2}/.test(str)) {
		return false;
	}
	const d = new Date(str);
	return !isNaN(d.getTime());
};

const getLastUpdatedValue = () => {
	const isCustomRangePickEnabled = fg('rovo-custom-date-range-picker');
	return LAST_UPDATED_VALUES.filter((value) => {
		if (value === 'custom') {
			return isCustomRangePickEnabled;
		}
		return true;
	});
};

const productKeys1P: ProductKeys1P[] = [
	ProductKeys.Atlas,
	ProductKeys.Confluence,
	ProductKeys.Focus,
	ProductKeys.Jira,
	ProductKeys.JiraProductDiscovery,
	ProductKeys.JiraServiceManagement,
	ProductKeys.Compass,
	ProductKeys.Trello,
	ProductKeys.Bitbucket,
];

const originalProducts3P: ProductKeys3P[] = [
	ProductKeys.Figma,
	ProductKeys.Github,
	ProductKeys.Google,
	ProductKeys.Loom,
	ProductKeys.OneDrive,
	ProductKeys.Slack,
	ProductKeys.Sharepoint,
	ProductKeys.Teams,
	ProductKeys.Dropbox,
	ProductKeys.Box,
];

const products3PFromConfig: ProductKeys3P[] = getProducts3PFromConfigForFilter('lastUpdated');

const getProducts3P = () => {
	return fg('rovo-full-page-search-3p-static-config') ? products3PFromConfig : originalProducts3P;
};

export const filter = createSingleWithCustomOptionSelectFilter({
	id: 'lastUpdated',
	products: [...productKeys1P, ...getProducts3P()],
	alwaysVisible: true,
	universal: true,
	queryParams: {
		key: 'lastModified',
		fetcher: async (_, config) => {
			return getLastUpdatedValue().map((value) => ({
				trackingKey: value,
				label:
					config.intl?.formatMessage(lastUpdatedLabels[value]) ||
					lastUpdatedLabels[value].defaultMessage,
				value,
				queryParamValue: value,
			})) satisfies FetchedSelectFilterOption[];
		},
		customValueParser: (queryParams) => {
			const { to, from } = queryParams;
			const isToDateValid = isIsoDate(to?.[0]);
			const isFromDateValid = isIsoDate(from?.[0]);
			const toDate = isToDateValid && to?.[0] ? to?.[0] : '';
			const fromDate = isFromDateValid && from?.[0] ? from?.[0] : '';
			const invalidDateRange = getInvalidDateRange(fromDate, toDate);
			if (!invalidDateRange) {
				return { to: toDate, from: fromDate };
			}
		},
	},
	initialOptions: {
		fetcher: async (config) => {
			return [
				{
					trackingKey: 'anytime',
					label:
						config.intl?.formatMessage(lastUpdatedLabels['anytime']) ||
						lastUpdatedLabels['anytime'].defaultMessage,
					value: 'anytime',
					isDefault: true,
					queryParamValue: LastModified.Anytime,
				},
				...getLastUpdatedValue().map((value) => ({
					trackingKey: value,
					label:
						config.intl?.formatMessage(lastUpdatedLabels[value]) ||
						lastUpdatedLabels[value].defaultMessage,
					value,
					queryParamValue: value,
				})),
			] satisfies FetchedSelectFilterOption[];
		},
	},
	sortCompareFn,
	customValueFields: ['to', 'from'],
});
