import FeatureGates from '@atlaskit/feature-gate-js-client';

import {
	Cohort,
	type ExperimentOverride,
	type FrontendExperimentProps,
	type GateOverride,
	type ServedExperiment,
	type SideBySideExperiment,
} from '../../../types';

export const getFrontendExperiment = ({ abTestId }: FrontendExperimentProps): ServedExperiment => {
	const cohort: string = FeatureGates.getExperiment(abTestId).get('cohort', Cohort.Default);
	const serveExperiment = cohort === Cohort.Test;
	return {
		serveExperiment,
		abTestId,
		cohort,
	};
};

type CreateCheckGateHandler = (overrides: SideBySideExperiment[] | undefined) => CheckGateHandler;
type CheckGateHandler = (name: string) => boolean;

/**
 * Create feature gate with overrides caller
 * - See https://hello.atlassian.net/wiki/x/AZH-AwE
 *
 * This function is designed to be called instead of
 * - import { fg } from '@atlaskit/platform-feature-flags'
 * - import { checkGate } from '@atlaskit/feature-gate-js-client';
 *
 * It will make use of overrides from Rovo vs Rovo mode if needed, calling to statsig if not (and if there are any errors)
 *
 * Falls back to a standard statsig call if:
 * - search-experiment-overrides-killswitch
 * - errors
 * - no overrides
 */
export const createFeatureGateHandler: CreateCheckGateHandler = (
	overrides: SideBySideExperiment[] | undefined,
) => {
	// eslint-disable-next-line @atlaskit/platform/use-recommended-utils
	if (FeatureGates.checkGate('search-experiment-overrides-killswitch')) {
		return defaultCheckGate;
	}

	if (!overrides) {
		return defaultCheckGate;
	}

	try {
		const gates = overrides.filter((overrides) => overrides.type === 'gate') as GateOverride[];

		const getFeatureGate = (name: string) => {
			const overrideFeatureGate = gates.find(
				(override) => 'name' in override && override.name === name,
			);

			if (!overrideFeatureGate) {
				return defaultCheckGate(name);
			}

			// Overrides always resolve to true as using an override is equivelant to turning FF on.
			return true;
		};

		return getFeatureGate;
	} catch (e) {
		console.error(e);
		return defaultCheckGate;
	}
};

type CreateFrontendExperimentHandler = (
	overrides?: SideBySideExperiment[] | undefined,
) => FrontendExperimentHandler;
type FrontendExperimentHandler = ({ abTestId }: FrontendExperimentProps) => ServedExperiment;

/**
 * Create frontend experiments with overrides caller
 * - See https://hello.atlassian.net/wiki/x/AZH-AwE
 *
 * This function is designed to be called instead of
 * - import { getFrontendExperiment } from '@atlassian/search-experiment'
 *
 * It will make use of overrides from Rovo vs Rovo mode if needed, calling to statsig if not (and if there are any errors)
 *
 * Falls back to a standard statsig call if:
 * - search-experiment-overrides-killswitch
 * - errors
 * - no overrides
 */
export const createFrontendExperimentHandler: CreateFrontendExperimentHandler = (
	overrides: SideBySideExperiment[] | undefined,
) => {
	// eslint-disable-next-line @atlaskit/platform/use-recommended-utils
	if (FeatureGates.checkGate('search-experiment-overrides-killswitch')) {
		return getFrontendExperiment;
	}

	if (!overrides) {
		return getFrontendExperiment;
	}

	try {
		const experiments = overrides.filter(
			(overrides) => overrides.type === 'experiment',
		) as ExperimentOverride[];

		const customGetFrontendExperiment = ({ abTestId }: { abTestId: string }) => {
			const overrideFrontendExperiment = experiments.find(
				(override) => 'name' in override && override.name === abTestId,
			);

			if (!overrideFrontendExperiment) {
				return getFrontendExperiment({ abTestId });
			}

			return {
				abTestId,
				serveExperiment: true,
				cohort:
					'cohort' in overrideFrontendExperiment ? overrideFrontendExperiment.cohort : Cohort.Test,
			} as ServedExperiment;
		};

		return customGetFrontendExperiment;
	} catch (e) {
		console.error(e);
		return getFrontendExperiment;
	}
};

// eslint-disable-next-line @atlaskit/platform/use-recommended-utils
const defaultCheckGate: CheckGateHandler = (name: string) => FeatureGates.checkGate(name);
