import React from 'react';

import AppsIcon from '@atlaskit/icon/core/apps';
import { B400, N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { type RovoLogoProps } from '../../constants/icons';
import { type NounKeys, nouns } from '../../constants/nouns';
import { type ProductKeys, products } from '../../constants/products';

export type ProductIconProps = RovoLogoProps & {
	product: ProductKeys | undefined;
	isSelected?: boolean;
};

export type ProductOrNounIconProps = RovoLogoProps & {
	productOrNounKey: ProductKeys | NounKeys | undefined;
	isSelected?: boolean;
};

export const ProductIcon = ({
	product,
	size = 'medium',
	isSelected = false,
	...props
}: ProductIconProps) => {
	let Icon;
	if (product) {
		Icon = products[product].Icon;
		return <Icon {...props} size={size} />;
	} else {
		return (
			<AppsIcon
				label=""
				spacing="spacious"
				color={isSelected ? token('color.icon.selected', B400) : token('color.icon', N500)}
			/>
		);
	}
};

export const ProductOrNounIcon = ({
	productOrNounKey,
	size = 'medium',
	isSelected = false,
	...props
}: ProductOrNounIconProps) => {
	let Icon;
	if (productOrNounKey) {
		Icon = products[productOrNounKey]?.Icon || nouns[productOrNounKey]?.Icon;
		return <Icon {...props} size={size} />;
	} else {
		return (
			<AppsIcon
				label=""
				spacing="spacious"
				color={isSelected ? token('color.icon.selected', B400) : token('color.icon', N500)}
			/>
		);
	}
};
