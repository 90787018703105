/**
 * An icon not available in Design System yet.
 * Copied from https://bitbucket.org/atlassian/status-ui/src/master/packages/ds-icon/glyph/atlas/project.tsx.
 */
import React from 'react';

export function ProjectIcon() {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.8284 6C14.5632 6 14.3088 6.10536 14.1213 6.29289L10.4591 9.95515L6.30769 10.4164C6.08201 10.4415 5.87158 10.5426 5.71101 10.7032L5.4142 11L6.53032 12.1161C7.01847 12.6043 7.01847 13.3957 6.53032 13.8839L5.20709 15.2071C4.81657 15.5976 4.1834 15.5976 3.79288 15.2071C3.40235 14.8166 3.40235 14.1834 3.79288 13.7929L4.58577 13L3.64643 12.0607C3.06064 11.4749 3.06065 10.5251 3.64643 9.93934L4.2968 9.28897C4.77849 8.80728 5.40978 8.50387 6.08683 8.42864L9.54092 8.04485L12.7071 4.87868C13.2697 4.31607 14.0328 4 14.8284 4H18C19.1046 4 20 4.89543 20 6V9.17157C20 9.96722 19.6839 10.7303 19.1213 11.2929L15.9551 14.4591L15.5713 17.9132C15.4961 18.5902 15.1927 19.2215 14.711 19.7032L14.0606 20.3536C13.4749 20.9393 12.5251 20.9393 11.9393 20.3536L11 19.4142L10.2071 20.2071C9.81657 20.5976 9.1834 20.5976 8.79288 20.2071C8.40235 19.8166 8.40235 19.1834 8.79288 18.7929L10.1161 17.4697C10.6043 16.9815 11.3957 16.9815 11.8839 17.4697L13 18.5858L13.2968 18.289C13.4574 18.1284 13.5585 17.918 13.5836 17.6923L14.0448 13.5409L17.7071 9.87868C17.8946 9.69114 18 9.43679 18 9.17157V6H14.8284ZM8.70711 15.2929C9.09763 15.6834 9.09763 16.3166 8.70711 16.7071L5.70711 19.7071C5.31658 20.0976 4.68342 20.0976 4.29289 19.7071C3.90237 19.3166 3.90237 18.6834 4.29289 18.2929L7.29289 15.2929C7.68342 14.9024 8.31658 14.9024 8.70711 15.2929ZM15 10C15.5523 10 16 9.55228 16 9C16 8.44772 15.5523 8 15 8C14.4477 8 14 8.44772 14 9C14 9.55228 14.4477 10 15 10Z"
				fill="currentColor"
			/>
		</svg>
	);
}
