import { fg } from '@atlaskit/platform-feature-flags';
import {
	getContributors,
	smartsRecommendedUserQuery,
	type User,
	usersQuery,
} from '@atlassian/search-client';

import {
	all1pProductKeys,
	all3pProductKeys,
	ProductKeys,
	type ProductKeys1P,
	ProductKeys3P,
} from '../../../../../common/constants/products';
import { getProducts3PFromConfigForFilter } from '../../../../../common/utils/filters';
import { createMultiSelectFilter } from '../../../../filters';
import { type FetchedSelectFilterOption } from '../../../../filters/base-select-filter/types';
import { type CloudConfig } from '../../../../filters/types';

const MAX_SMART_DEFAULT_OPTIONS = 5;
const MAX_SEARCHED_OPTIONS_RESULTS = 5;
export const CONTRIBUTOR_FILTER_QUERY_KEYWORDS = ['@', 'contributor:'];

const loadRecommendedContributor = async (config: CloudConfig) => {
	const initialUsersQuery = usersQuery([
		...(config.userId ? [config.userId] : []), // The current user should always be the first recommended user
	]);

	const recommendedUserQuery = smartsRecommendedUserQuery({
		aggAbsoluteUrl: config.aggAbsoluteUrl,
		variables: {
			userId: config.userId || '',
			tenantId: config.cloudId || '',
			maxNumberOfResults: MAX_SMART_DEFAULT_OPTIONS,
		},
	});

	const [initialUsersReponse, smartsRecommendedUserResponse] = await Promise.all([
		initialUsersQuery,
		recommendedUserQuery,
	]);

	const recommendedUsersResults = smartsRecommendedUserResponse.data?.smarts.results ?? [];

	const allRecommendedUsers = [
		...(initialUsersReponse.data?.users ?? []),
		...recommendedUsersResults.flatMap((recommendedUsers) =>
			!!recommendedUsers && !!recommendedUsers.user
				? { id: recommendedUsers.id, ...recommendedUsers.user }
				: [],
		),
	] satisfies User[];

	const recommendedUserOptions = allRecommendedUsers.map(
		(user) =>
			({
				trackingKey: user.accountId,
				value: user.accountId,
				label: user.name,
				avatarUrl: user.picture,
				queryParamValue: user.accountId,
			}) satisfies FetchedSelectFilterOption,
	);

	return recommendedUserOptions;
};
// eslint-disable-next-line @atlaskit/platform/no-module-level-eval
const isUniversalContributorFilter = fg('universal-contributor-filter');

// Filter out Trello for milestone 1 (QS-4733)
const productKeys1P: ProductKeys1P[] = isUniversalContributorFilter
	? all1pProductKeys.filter((key) => key !== ProductKeys.Trello)
	: [ProductKeys.Confluence];

const originalProducts3P: ProductKeys3P[] = [];

const products3PFromConfig: ProductKeys3P[] = getProducts3PFromConfigForFilter('contributor');

const getProducts3P = () => {
	return fg('rovo-full-page-search-3p-static-config') ? products3PFromConfig : originalProducts3P;
};

// Figma doesn't support contributor
// https://atlassian.slack.com/archives/C07P3MD2U9Z/p1733868616441159?thread_ts=1733866616.102409&cid=C07P3MD2U9Z
const all3pProductKeysExceptFigma = all3pProductKeys.filter((key) => key !== ProductKeys3P.Figma);

export const filter = createMultiSelectFilter({
	id: 'contributor',
	// 1P only for now (excluding Trello)
	products: [
		...productKeys1P,
		...(isUniversalContributorFilter ? all3pProductKeysExceptFigma : getProducts3P()),
	],
	alwaysVisible: true,
	universal: isUniversalContributorFilter,
	queryParams: {
		key: 'contributors',
		fetcher: async (queryParams, _) => {
			const initialUsersReponse = await usersQuery([...queryParams]);

			const allRecommendedUsers = initialUsersReponse.data?.users ?? ([] satisfies User[]);

			const recommendedUserOptions = allRecommendedUsers.map(
				(user) =>
					({
						trackingKey: user.accountId,
						value: user.accountId,
						label: user.name,
						avatarUrl: user.picture,
						queryParamValue: user.accountId,
					}) satisfies FetchedSelectFilterOption,
			);

			return recommendedUserOptions;
		},
	},
	initialOptions: {
		fetcher: async (config) => {
			return loadRecommendedContributor(config);
		},
	},
	lookup: {
		fetcher: async (query, config) => {
			if (!config.intl) {
				return []; // base on existing interface, can only query contributors with intl
			}

			const response = await getContributors({
				query,
				cloudId: config.cloudId || '',
				userId: config.userId || '',
				productKey: ProductKeys.Confluence,
				maxNumberOfResults: MAX_SEARCHED_OPTIONS_RESULTS,
				intl: config.intl,
			});

			if (!response) {
				// TODO: log not found somewhere
				return [];
			}

			return response.map(
				(contributor) =>
					({
						trackingKey: contributor.id,
						value: contributor.id,
						label: contributor.name,
						avatarUrl: contributor.avatarUrl,
						queryParamValue: contributor.id,
					}) satisfies FetchedSelectFilterOption,
			);
		},
	},
});
