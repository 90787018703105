import type {
	AtlasEntityATI,
	CloudGraphCalendarEventEntityAti,
	CloudGraphConversationEntityAti,
	CloudGraphDesignEntityAti,
	CloudGraphDevInfoEntityAti,
	CloudGraphDocumentEntityAti,
	CloudGraphMessageEntityAti,
	CloudGraphVideoEntityAti,
	CloudGraphWorkItemEntityAti,
	ConfluenceEntityATI,
	EntityATI,
	JiraEntityATI,
	MercuryEntityATI,
	SlackEntityType,
} from '@atlassian/search-client';

export const DEFAULT_ATLAS_ENTITY_ATI: AtlasEntityATI[] = [
	'ati:cloud:townsquare:project',
	'ati:cloud:townsquare:goal',
	'ati:cloud:townsquare:tag',
	// 'ati:cloud:townsquare:question',
	// 'ati:cloud:townsquare:learning',
];

export const DEFAULT_MERCURY_ENTITY_ATI: MercuryEntityATI[] = [
	'ati:cloud:mercury:focus-area',
	// 'ati:cloud:mercury:focus-area-status-update',
];

export const DEFAULT_CLOUD_GRAPH_DOCUMENT_ENTITY_ATI: CloudGraphDocumentEntityAti[] = [
	'ati:cloud:graph:document',
];
export const DEFAULT_CLOUD_GRAPH_MESSAGE_ENTITY_ATI: CloudGraphMessageEntityAti[] = [
	'ati:cloud:graph:message',
];
export const DEFAULT_CLOUD_GRAPH_CONVERSATION_ENTITY_ATI: CloudGraphConversationEntityAti[] = [
	'ati:cloud:graph:conversation',
];
export const DEFAULT_CLOUD_GRAPH_DESIGN_ENTITY_ATI: CloudGraphDesignEntityAti[] = [
	'ati:cloud:graph:design',
];
export const DEFAULT_CLOUD_GRAPH_VIDEO_ENTITY_ATI: CloudGraphVideoEntityAti[] = [
	'ati:cloud:graph:video',
];

export const DEFAULT_CLOUD_GRAPH_WORK_ITEM_ENTITY_ATI: CloudGraphWorkItemEntityAti[] = [
	'ati:cloud:graph:work-item',
];

export const DEFAULT_CLOUD_GRAPH_CALENDAR_EVENT_ENTITY_ATI: CloudGraphCalendarEventEntityAti[] = [
	'ati:cloud:graph:calendar-event',
];

export const DEFAULT_CLOUD_GRAPH_DEVINFO_ENTITY_ATI: CloudGraphDevInfoEntityAti[] = [
	'ati:cloud:graph:repository',
	'ati:cloud:graph:pull-request',
	'ati:cloud:graph:commit',
	'ati:cloud:graph:branch',
];

export const confluenceEntityATIPrefix = 'ati:cloud:confluence:';

export const DEFAULT_CONFLUENCE_ENTITY_ATI: ConfluenceEntityATI[] = [
	'ati:cloud:confluence:page',
	'ati:cloud:confluence:blogpost',
	'ati:cloud:confluence:attachment',
	'ati:cloud:confluence:space',
	'ati:cloud:confluence:whiteboard',
];

// TODO check references
export const PARITY_CONFLUENCE_ENTITY_ATI: ConfluenceEntityATI[] = [
	'ati:cloud:confluence:comment',
	'ati:cloud:confluence:database',
	'ati:cloud:confluence:embed',
	'ati:cloud:confluence:folder',
];

export const CONFLUENCE_CALENDAR_ENTITY_ATI: ConfluenceEntityATI =
	'confluence.com.atlassian.confluence.extra.team-calendars:calendar-content-type';

export const CONFLUENCE_SPACE_CALENDAR_ENTITY_ATI: ConfluenceEntityATI =
	'confluence.com.atlassian.confluence.extra.team-calendars:space-calendars-view-content-type';

export const CONFLUENCE_ANSWER_ENTITY_ATI: ConfluenceEntityATI =
	'confluence.ac:com.atlassian.confluence.plugins.confluence-questions:answer';

export const CONFLUENCE_QUESTION_ENTITY_ATI: ConfluenceEntityATI =
	'confluence.ac:com.atlassian.confluence.plugins.confluence-questions:question';

export const JIRA_ISSUE_ENTITY_ATI = 'ati:cloud:jira:issue';
export const JIRA_PROJECT_ENTITY_ATI = 'ati:cloud:jira:project';

export const DEFAULT_JIRA_ENTITY_ATI: JiraEntityATI[] = [
	JIRA_PROJECT_ENTITY_ATI,
	JIRA_ISSUE_ENTITY_ATI,
	// 'ati:cloud:jira:filter',
	// 'ati:cloud:jira:dashboard',
	// 'ati:cloud:jira:board',
];

export const DEFAULT_SLACK_TYPES: SlackEntityType[] = ['message'];

export const DEFAULT_ENTITY_ATI = [
	...DEFAULT_ATLAS_ENTITY_ATI,
	...DEFAULT_CONFLUENCE_ENTITY_ATI,
	...DEFAULT_JIRA_ENTITY_ATI,
	...DEFAULT_MERCURY_ENTITY_ATI,
] satisfies EntityATI[];

/**
 * Think before bumping the limits as it increases the time spent in the hydration layer.
 * We hydrate quite a few fields from Confluence , Atlas and Jira which can slow downt the calls further.
 */
export const RESULT_LIMIT_FOR_MULTIPLE_PRODUCTS = 15;
export const RESULT_LIMIT_FOR_SINGLE_PRODUCT = 30;
