import { fg } from '@atlaskit/platform-feature-flags';
import {
	confluenceSpacesQuery,
	searchEntitiesQuery,
	smartsRecommendedContainerQuery,
} from '@atlassian/search-client';

import {
	ProductKeys,
	type ProductKeys1P,
	type ProductKeys3P,
} from '../../../../../common/constants/products';
import { getProducts3PFromConfigForFilter } from '../../../../../common/utils/filters';
import { createMultiSelectFilter } from '../../../../filters';
import { type FetchedSelectFilterOption } from '../../../../filters/base-select-filter/types';

type Space = {
	/** Space ARI */
	ari: string;
	/** Space key, e.g. "MICROS" */
	key: string;
	name: string | null;
	avatarUrl: string;
};

const productKeys1P: ProductKeys1P[] = [ProductKeys.Confluence];

const originalProducts3P: ProductKeys3P[] = [];

const products3PFromConfig: ProductKeys3P[] = getProducts3PFromConfigForFilter('space');

const getProducts3P = () => {
	return fg('rovo-full-page-search-3p-static-config') ? products3PFromConfig : originalProducts3P;
};

export const filter = createMultiSelectFilter({
	id: 'space',
	products: [...productKeys1P, ...getProducts3P()],
	alwaysVisible: false,
	universal: false,
	queryParams: {
		key: 'spaces',
		fetcher: async (_, config) => {
			// use initial space ARIs from config as we can not directly use space value in queryParams
			const initialSpacesResponse = await confluenceSpacesQuery(config.initialSpaceARIs || []);

			const allRecommendedSpaces: Space[] =
				initialSpacesResponse.data?.confluence.spaces.map((space) => ({
					key: space.key,
					name: space.name,
					ari: space.id,
					avatarUrl:
						space.links?.base && space.icon?.path ? `${space.links.base}${space.icon.path}` : '',
				})) || [];

			return allRecommendedSpaces.map(
				(space) =>
					({
						trackingKey: space.ari,
						value: space.key,
						queryParamValue: space.key,
						avatarUrl: space.avatarUrl,
						label: space.name || '',
					}) satisfies FetchedSelectFilterOption,
			);
		},
	},
	initialOptions: {
		fetcher: async (config) => {
			const recommendedSpacesResponse = await smartsRecommendedContainerQuery({
				aggAbsoluteUrl: config.aggAbsoluteUrl,
				variables: {
					userId: config.userId || '',
					tenantId: config.cloudId || '',
				},
			});

			const allRecommendedSpaces: Space[] =
				recommendedSpacesResponse.data?.smarts.results?.flatMap(({ container }) => {
					if (!(container && container.key)) {
						return [];
					}
					return [
						{
							key: container.key,
							name: container.name,
							ari: container.id,
							avatarUrl:
								container.links?.base && container.icon?.path
									? `${container.links.base}${container.icon.path}`
									: '',
						},
					];
				}) || [];

			const recommendedSpaceOptions = allRecommendedSpaces.map((space) => {
				return {
					trackingKey: space.ari,
					value: space.key,
					queryParamValue: space.key,
					avatarUrl: space.avatarUrl,
					label: space.name || '',
				} satisfies FetchedSelectFilterOption;
			});

			return recommendedSpaceOptions;
		},
	},
	lookup: {
		fetcher: async (query, config) => {
			const response = await searchEntitiesQuery({
				aggAbsoluteUrl: config.aggAbsoluteUrl,
				variables: {
					query,
					first: 15,
					filters: {
						entities: ['ati:cloud:confluence:space'],
						locations: [`ari:cloud:confluence::site/${config.cloudId}`],
					},
					experience: 'full-page-search-space-filter',
				},
				includeSpaceKey: true,
			});
			const { data } = response;
			if (!data) {
				return [];
			}

			return (
				data.search?.results.edges.map(
					({ node }) =>
						({
							trackingKey: node.id || '',
							value: node.key || '',
							queryParamValue: node.key ?? '',
							avatarUrl: node.iconUrl || undefined,
							label: node.title,
						}) satisfies FetchedSelectFilterOption,
				) ?? []
			);
		},
	},
});
