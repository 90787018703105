import { TypeFilterValueKey } from '../../filters';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { CanvaFilterIcon } from './icon';
import { CanvaIcon } from './search-result-icon';

export const canvaConfig: ThirdPartyConfig = {
	displayName: 'Canva',
	id: 'canva',
	resourceType: 'canva-connector',
	icon: RovoLogoFromGlyph(CanvaFilterIcon, 'Canva'),
	searchResultIcon: RovoLogoFromGlyph(CanvaIcon, 'Canva'),
	integrationAri: 'ari:cloud:platform::integration/canva',
	typeFilterValues: [TypeFilterValueKey.Design],
	requiresOauth: true,
	isEnabled: () => false,
};
