import React from 'react';

export const BoxIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24px"
		height="24px"
		viewBox="0 0 43 40"
		version="1.1"
	>
		<g id="surface1">
			<path
				d="M35.871 40H7.13C3.19 40 0 37.031 0 33.371V6.63C0 2.969 3.191 0 7.129 0H35.87C39.81 0 43 2.969 43 6.629V33.37C43 37.031 39.809 40 35.871 40"
				stroke="none"
				fillRule="nonzero"
				fill="#00aeef"
				fillOpacity="1"
			/>
			<path
				d="M22.008 16.84c-2.238 0-4.184 1.172-5.164 2.89-.98-1.718-2.926-2.89-5.168-2.89a6.142 6.142 0 0 0-3.508 1.09v-4.582C8.156 12.754 7.637 12.28 7 12.28c-.64 0-1.152.473-1.168 1.067v9.023c.055 2.961 2.648 5.348 5.844 5.348 2.242 0 4.187-1.172 5.168-2.89.98 1.718 2.926 2.89 5.164 2.89 3.226 0 5.844-2.434 5.844-5.442 0-3-2.618-5.437-5.844-5.437m-10.332 8.703c-1.934 0-3.508-1.461-3.508-3.262 0-1.804 1.574-3.261 3.508-3.261 1.937 0 3.508 1.457 3.508 3.257 0 1.805-1.57 3.266-3.508 3.266m10.332 0c-1.938 0-3.508-1.461-3.508-3.266 0-1.8 1.57-3.257 3.508-3.257 1.937 0 3.508 1.457 3.508 3.257 0 1.805-1.57 3.266-3.508 3.266"
				stroke="none"
				fillRule="nonzero"
				fill="#fff"
				fillOpacity="1"
			/>
			<path
				d="m36.926 25.898-3.18-3.625 3.184-3.632c.402-.48.285-1.149-.27-1.504-.555-.36-1.336-.27-1.765.195l-2.739 3.121-2.734-3.121c-.426-.465-1.211-.555-1.766-.195-.554.355-.668 1.023-.265 1.504l3.175 3.632-3.175 3.625c-.403.48-.29 1.149.265 1.504.555.356 1.34.266 1.766-.199l2.734-3.117 2.735 3.117c.43.465 1.21.555 1.765.2.555-.356.672-1.024.27-1.505"
				stroke="none"
				fillRule="nonzero"
				fill="#fff"
				fillOpacity="1"
			/>
		</g>
	</svg>
);
