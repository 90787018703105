import type { EntityATI, ExperimentLayer, SearchJiraProductType } from '../../../common/types';

import { getAtlasPartials } from './atlas';
import { getCompassPartials } from './compass';
import { getConfluencePartials } from './confluence';
import { getJiraPartials } from './jira';
import { getMercuryPartials } from './mercury';
import { type ResultsSort } from './results-sort';
import { getGraphPartials } from './third-party-graph-entity';
import { getGooglePartials, getMicrosoftPartials, getSlackPartials } from './third-party-legacy';
import { getTrelloPartials } from './trello';

export interface CommonFilters {
	range?: {
		lastModified?: {
			gt?: string;
			lt?: string;
		};
	};
	contributorsFilter?: string[];
}

export type SearchConfluenceRangeField = 'LASTMODIFIED' | 'CREATED';

export interface ConfluenceRangeFilter {
	field: SearchConfluenceRangeField;
	gt?: string;
	lt?: string;
}

export interface ConfluenceFilters {
	spacesFilter: string[];
	contributorsFilter: string[];
	ancestorIdsFilter: string[];
	range: ConfluenceRangeFilter[];
	isVerified?: boolean;
	owners: string[];
	labelsFilter: string[];
	titleMatchOnly?: boolean;
}

export interface JiraFilters {
	issueFilter: {
		projectARIs: string[];
		assigneeARIs: string[];
		issueTypeIDs: string[];
	};
	projectFilter?: {
		projectTypes?: SearchJiraProductType[];
	};
}

export type SearchThirdPartyRangeField = 'LASTMODIFIED' | 'CREATED';

export interface ThirdPartyRangeFilter {
	field: SearchThirdPartyRangeField;
	gt?: string;
	lt?: string;
}

export interface ThirdPartyFilters {
	range?: ThirdPartyRangeFilter[];
	titleMatchOnly: boolean;
	subtypes: string[];
	integrations?: string[];
}

export type SearchPageQueryVariables = {
	query: string;
	cloudIdARI: string;
	tenantId: string;
	limit: number;
	entities: EntityATI[]; // ATI of the types needed
	commonFilters: CommonFilters;
	confluenceFilters: ConfluenceFilters;
	jiraFilters: JiraFilters;
	thirdPartyFilters: ThirdPartyFilters;
	queryVersion: number;
	searchSessionId: string;
	sourceProduct?: string;
	sort: ResultsSort[];
	experimentId?: string;
	shadowExperimentId?: string;
	experimentLayers?: ExperimentLayer[];
	after?: string;
	disableQueryReplacement?: boolean;
};
export type ResultsCountQueryVariables = {
	query: string;
	cloudIdARI: string;
	limit: number;
	entities: EntityATI[]; // ATI of the types needed
	commonFilters: CommonFilters;
	confluenceFilters: ConfluenceFilters;
	jiraFilters: JiraFilters;
	thirdPartyFilters: ThirdPartyFilters;
	queryVersion: number;
	searchSessionId: string;
	sourceProduct?: string;
	sort: any[];
	after?: string;
	disableQueryReplacement?: boolean;
};

/**
 * These attributes are the base search result attributes and are requested for every entity.
 */
export const SEARCH_RESULTS_ATTRIBUTES = `
description
iconUrl
id
title
type
url
__typename
`;

export const getSupportedPartials = () => {
	return `
		${getConfluencePartials()}
		${getJiraPartials()}
		${getTrelloPartials()}
		${getAtlasPartials()}
		${getCompassPartials()}
		${getMercuryPartials()}
		${getGooglePartials()}
		${getMicrosoftPartials()}
		${getSlackPartials()}
		${getGraphPartials()}
	`;
};

export const operationName = 'SearchPageQuery';

export const queryGenerator = () => {
	const partials = getSupportedPartials();

	const graphQuery = `
	query ${operationName}(
		$query: String!
		$cloudIdARI: String!
		$tenantId: ID!
		$limit: Int!
		$entities: [String!]!
		$queryVersion: Int
		$searchSessionId: String
		$commonFilters: SearchCommonFilter
		$confluenceFilters: SearchConfluenceFilter
		$jiraFilters: SearchJiraFilter
		$thirdPartyFilters:  SearchThirdPartyFilter
		$sort: [SearchSortInput]
		$sourceProduct: String
		$experimentId: String
		$shadowExperimentId: String
		$experimentLayers: [SearchExperimentLayer]
		$after: String
		$disableQueryReplacement: Boolean
	) {
		search {
			results: search(
				experience: "search-page"
				query: $query
				first: $limit
				after: $after
				sort: $sort
				disableQueryReplacement: $disableQueryReplacement
				filters: {
					entities: $entities
					locations: [$cloudIdARI]
					commonFilters: $commonFilters
					confluenceFilters: $confluenceFilters
					jiraFilters: $jiraFilters
					thirdPartyFilters: $thirdPartyFilters
				}
		analytics: {
					queryVersion: $queryVersion
					searchSessionId: $searchSessionId
					sourceProduct: $sourceProduct
				}
		experimentContext: {
			experimentId: $experimentId
			shadowExperimentId: $shadowExperimentId
			experimentLayers: $experimentLayers
		}
			) {
				edges {
					node {
						${SEARCH_RESULTS_ATTRIBUTES}
						${partials}
					}
				}
				queryInfo {
					originalQuery
					replacementQuery
					suggestedQuery
					originalQueryType
					confidenceScore
					shouldTriggerAutocorrectionExperience
				}
				abTest {
					abTestId
					controlId
					experimentId
				}
				totalCount
				totalCounts {
					product
					count
				}
        pageInfo {
          endCursor
        }
			}
		}
	}
	`;
	return graphQuery;
};

export const resultsCountOperationName = 'ResultsCountQuery';

export const resultsCountQueryGenerator = () => {
	const graphQuery = `
	query ${resultsCountOperationName}(
		$query: String!
		$cloudIdARI: String!
		$limit: Int!
		$entities: [String!]!
		$queryVersion: Int
		$searchSessionId: String
		$commonFilters: SearchCommonFilter
		$confluenceFilters: SearchConfluenceFilter
		$jiraFilters: SearchJiraFilter
		$thirdPartyFilters:  SearchThirdPartyFilter
		$sort: [SearchSortInput]
		$sourceProduct: String
		$experimentId: String
		$shadowExperimentId: String
		$experimentLayers: [SearchExperimentLayer]
		$disableQueryReplacement: Boolean
	) {
		search {
			results: search(
				experience: "search-page"
				query: $query
				first: $limit
				sort: $sort
				disableQueryReplacement: $disableQueryReplacement
				filters: {
					entities: $entities
					locations: [$cloudIdARI]
					commonFilters: $commonFilters
					confluenceFilters: $confluenceFilters
					jiraFilters: $jiraFilters
					thirdPartyFilters: $thirdPartyFilters
				}
		analytics: {
					queryVersion: $queryVersion
					searchSessionId: $searchSessionId
					sourceProduct: $sourceProduct
				}
		experimentContext: {
			experimentId: $experimentId
			shadowExperimentId: $shadowExperimentId
			experimentLayers: $experimentLayers
		}
			) {

				queryInfo {
					originalQuery
					replacementQuery
					originalQueryType
					confidenceScore
					shouldTriggerAutocorrectionExperience
				}
				abTest {
					abTestId
					controlId
					experimentId
				}
				totalCount
				totalCounts {
					product
					count
				}
			}
		}
	}
	`;
	return graphQuery;
};
