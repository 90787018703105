import { TypeFilterValueKey } from '../../filters';
import { RovoLogoFromGlyph } from '../../icons';
import { type ThirdPartyConfig } from '../../schemas/3p-config';

import { MiroFilterIcon } from './icon';
import { MiroIcon } from './search-result-icon';

export const miroConfig: ThirdPartyConfig = {
	displayName: 'Miro',
	id: 'miro',
	resourceType: 'miro-connector',
	icon: RovoLogoFromGlyph(MiroFilterIcon, 'Miro'),
	searchResultIcon: RovoLogoFromGlyph(MiroIcon, 'Miro'),
	integrationAri: 'ari:cloud:platform::integration/miro',
	typeFilterValues: [TypeFilterValueKey.Design],
	filters: ['lastUpdated'],
	requiresOauth: true,
	isEnabled: () => true,
};
