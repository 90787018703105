import { type QueryParams } from '../types';

/**
 * Extracts the base URL from a given URL if it exists. Otherwise, it returns the current origin.
 * @param url string or undefined
 * @returns base URL or current origin
 */
export function getBaseUrl(url?: string): string {
	return url ? new URL(url).origin : window.location.origin;
}

/**
 * Generates a search URL with the given query parameters.
 * @param queryParams Record<string, string>
 * @returns search URL
 */
export function defaultGenerateSearchUrl(queryParams: QueryParams): string {
	const url = new URL(window.location.href);
	const searchParams = new URLSearchParams();
	Object.entries(queryParams).forEach(([key, value]) => {
		if (value) {
			searchParams.set(key, String(value));
		}
	});
	url.search = searchParams.toString();
	return url.href;
}

export function defaultOnNavigate(url: string) {
	window.location.href = url;
}
