import React, { Fragment } from 'react';

import type { ProductType } from '@atlassian/search-client';

import { products } from '../../constants/products';
import { Spacer } from '../metadata-spacer';
import { Show } from '../width-observer';

/** A metadata part to render */
export type SearchMetadataPart = string | null | [string, React.ReactNode];

const renderParts = (part: SearchMetadataPart, spacer: boolean) => {
	const [key, value] = Array.isArray(part) ? part : [part, part];
	return (
		<Fragment key={key}>
			{value}
			{spacer && <Spacer />}
		</Fragment>
	);
};

const filterPart = (part: SearchMetadataPart): boolean => {
	if (Array.isArray(part)) {
		return !!part[1];
	}
	return !!part;
};

export type SearchMetadataProps = {
	product?: ProductType;
	productSelected?: boolean;
	/** Nullish/empty string parts are filtered out. */
	parts?: Array<SearchMetadataPart>;
};

export const SearchMetadata = ({
	product,
	productSelected,
	parts: defaultParts = [],
}: SearchMetadataProps) => {
	const productDisplayName =
		!product || productSelected ? '' : products[product]?.displayName || '';
	const parts = defaultParts.filter(filterPart);

	return (
		<>
			{productDisplayName && (
				<Show target="layout" above="sm">
					{productDisplayName}
					<Spacer />
				</Show>
			)}
			<>{parts?.map((m, i) => renderParts(m, i < parts.length - 1))}</>
		</>
	);
};
