import React, {
	createContext,
	type PropsWithChildren,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';

import debounce from 'lodash/debounce';

interface WidthObserverContextProps {
	registerElement: (id: string, element: HTMLElement) => void;
	unregisterElement: (id: string) => void;
	subscribe: (id: string, callback: (width: number) => void) => () => void;
}

const WidthObserverContext = createContext<WidthObserverContextProps | undefined>(undefined);

export const WidthObserverProvider = ({ children }: PropsWithChildren<unknown>) => {
	const [elements, setElements] = useState<Record<string, HTMLElement>>({});
	const [callbacks, setCallbacks] = useState<Record<string, ((width: number) => void)[]>>({});

	useEffect(() => {
		const handleResize = debounce(
			(entries: ResizeObserverEntry[]) => {
				entries.forEach((entry) => {
					const id = entry.target.getAttribute('data-width-observer-id');
					if (id && callbacks[id]) {
						const width = entry.contentRect.width;
						callbacks[id].forEach((callback) => callback(width));
					}
				});
			},
			100,
			{ leading: true, trailing: true },
		);

		const observer = new ResizeObserver(handleResize);

		Object.values(elements).forEach((element) => observer.observe(element));

		return () => {
			observer.disconnect();
		};
	}, [elements, callbacks]);

	const registerElement = useCallback(
		(id: string, element: HTMLElement) => {
			setElements((prev) => ({ ...prev, [id]: element }));
		},
		[setElements],
	);

	const unregisterElement = useCallback(
		(id: string) => {
			setElements((prev) => {
				const newElements = { ...prev };
				delete newElements[id];
				return newElements;
			});
		},
		[setElements],
	);

	const subscribe = useCallback(
		(id: string, callback: (width: number) => void) => {
			setCallbacks((prev) => ({
				...prev,
				[id]: [...(prev[id] || []), callback],
			}));
			return () => {
				setCallbacks((prev) => ({
					...prev,
					[id]: (prev[id] || []).filter((cb) => cb !== callback),
				}));
			};
		},
		[setCallbacks],
	);

	return (
		<WidthObserverContext.Provider value={{ registerElement, unregisterElement, subscribe }}>
			{children}
		</WidthObserverContext.Provider>
	);
};

export const useWidthObserver = () => {
	const context = useContext(WidthObserverContext);
	if (!context) {
		throw new Error('useWidthObserver must be used within a WidthObserverProvider');
	}
	return context;
};

export const WidthObserverElement = ({
	id,
	children,
}: {
	id: string;
	children: React.ReactElement;
}) => {
	const { registerElement, unregisterElement } = useWidthObserver();
	const elementRef = useRef<HTMLElement>(null);

	useEffect(() => {
		const element = elementRef.current;
		if (element) {
			element.setAttribute('data-width-observer-id', id);
			registerElement(id, element);
		}
		return () => {
			unregisterElement(id);
		};
	}, [id, registerElement, unregisterElement]);

	return <>{React.cloneElement(children, { ref: elementRef })}</>;
};

export const useElementWidth = (id: string) => {
	const { subscribe } = useWidthObserver();
	const [width, setWidth] = useState(0);

	useEffect(() => {
		const unsubscribe = subscribe(id, setWidth);
		return () => {
			unsubscribe();
		};
	}, [id, subscribe]);

	return width;
};

export const WidthObserverSubscriber = ({
	target: id,
	children,
}: {
	target: string;
	children: (width: number) => JSX.Element;
}) => {
	const width = useElementWidth(id);

	if (width === 0) {
		return null;
	}

	return children(width);
};

const breakpoints = {
	xxs: 0,
	xs: 480,
	sm: 768,
	md: 1024,
	lg: 1440,
	xl: 1768,
} as const;

export const isBreakpointActive = ({
	width,
	above,
	below,
}: {
	width: number;
	above?: keyof typeof breakpoints;
	below?: keyof typeof breakpoints;
}) => {
	return (above && width > breakpoints[above]) || (below && width < breakpoints[below]) || false;
};

interface ShowHideProps {
	target: string;
	above?: keyof typeof breakpoints;
	below?: keyof typeof breakpoints;
	children: React.ReactNode;
}

export const Show = ({ target, above, below, children }: ShowHideProps) => {
	return (
		<WidthObserverSubscriber target={target}>
			{(width) => {
				if (isBreakpointActive({ width, above, below })) {
					return <>{children}</>;
				}
				return <></>;
			}}
		</WidthObserverSubscriber>
	);
};

export const Hide = ({ target, above, below, children }: ShowHideProps) => {
	return (
		<WidthObserverSubscriber target={target}>
			{(width) => {
				if (isBreakpointActive({ width, above, below })) {
					return <></>;
				}
				return <>{children}</>;
			}}
		</WidthObserverSubscriber>
	);
};
