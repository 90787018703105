import type { Maybe, SearchResult } from '../../../../common/types';

const SearchConfluencePageBlogAttachment = `
... on SearchConfluencePageBlogAttachment {
	lastModified
	isVerified
	space {
		id
		name
		webUiLink
	}
	__typename
	confluenceEntity {
		... on ConfluencePage {
			ancestors {
				id
				title
				links {
					webUi
				}
			}
			metadata {
				titleEmojiPublished {
					id
					value
				}
			}
			commentCountSummary {
				total
			}
			likesSummary {
				count
			}
		}
	}
	socialSignal(tenantId: $tenantId) {
		signal
		timestamp
		user {
			accountId
			name
			picture
			... on AtlassianAccountUser {
				email
				extendedProfile {
					jobTitle
				}
			}
		}
	}
}
`;

export interface SearchConfluencePageBlogAttachment extends SearchResult {
	lastModified?: Maybe<string>;
	isVerified: boolean | null;
	confluenceEntity?: {
		ancestors: {
			id: string;
			title: string;
			links?: {
				webUi: string;
			};
		}[];
		metadata: Maybe<{
			titleEmojiPublished: Maybe<{
				id: string;
				value: string;
			}>;
		}>;
		commentCountSummary: Maybe<{
			total: Maybe<number>;
		}>;
		likesSummary: Maybe<{
			count: Maybe<number>;
		}>;
	};
	space?: Maybe<{
		id: string;
		name: string;
		webUiLink?: string;
	}>;
	socialSignal: Maybe<{
		/** Should be either "comment" , "edit", or "share". */
		signal: Maybe<string>;
		timestamp: Maybe<string>;
		user: Maybe<{
			accountId: string;
			name: string;
			picture: string;
			email: Maybe<string>;
			extendedProfile: Maybe<{
				jobTitle: Maybe<string>;
			}>;
		}>;
	}>;
	__typename: 'SearchConfluencePageBlogAttachment';
}

export const isSearchConfluencePageBlogAttachment = (
	partial: SearchResult,
): partial is SearchConfluencePageBlogAttachment => {
	return partial.__typename === 'SearchConfluencePageBlogAttachment';
};

const SearchConfluenceSpace = `
... on SearchConfluenceSpace {
	key
	lastModified
	iconPath
	socialSignal(tenantId: $tenantId) {
		signal
		timestamp
		user {
			accountId
			name
			picture
			... on AtlassianAccountUser {
				email
				extendedProfile {
					jobTitle
				}
			}
		}

	}
}
`;

export interface SearchConfluenceSpace extends SearchResult {
	key?: string;
	lastModified?: Maybe<string>;
	iconPath?: Maybe<string>;
	socialSignal: Maybe<{
		/** Should be either "comment" , "edit", or "share". */
		signal: Maybe<string>;
		timestamp: Maybe<string>;
		user: Maybe<{
			accountId: string;
			name: string;
			picture: string;
			email: Maybe<string>;
			extendedProfile: Maybe<{
				jobTitle: Maybe<string>;
			}>;
		}>;
	}>;
	__typename: 'SearchConfluenceSpace';
}

export const isSearchConfluenceSpace = (
	partial: SearchResult,
): partial is SearchConfluenceSpace => {
	return partial.__typename === 'SearchConfluenceSpace';
};

export type SearchConfluencePartial = SearchConfluencePageBlogAttachment | SearchConfluenceSpace;

export const isSearchConfluencePartial = (
	partial: SearchResult,
): partial is SearchConfluencePartial => {
	return isSearchConfluencePageBlogAttachment(partial) || isSearchConfluenceSpace(partial);
};

export const getConfluencePartials = () => {
	return `
		${SearchConfluencePageBlogAttachment}
		${SearchConfluenceSpace}
	`;
};
