import { type ResultsSort } from '@atlassian/search-client';

import {
	ALL_PRODUCTS_STRING,
	ARCHIVED,
	CROSSPRODUCT_SEARCH_API,
	CURRENT,
	OptionIds,
} from '../../common/constants/analytics';
import { type ApplicationModes } from '../../common/constants/application-modes';
import { is3pProductKey } from '../../common/constants/products';
import { type ExperimentConfig } from '../../common/constants/types';
import { type CommonAttributes, type FilterAttributes } from '../../common/utils/events/types';
import { sha256Hash } from '../../common/utils/events/utils';

type GetAttributesType = {
	searchSessionId: string;
	searchQuery: string;
	inputQuery: string;
	filters: FilterAttributes;
	queryUpdatedTime?: number;
	productSelected?: string;
	availableProducts: string[];
	sort: ResultsSort;
	experimentConfig: ExperimentConfig;
	applicationMode: ApplicationModes;
};

export const getAttributes = ({
	searchSessionId,
	searchQuery,
	inputQuery,
	filters,
	queryUpdatedTime,
	productSelected,
	availableProducts,
	sort,
	experimentConfig,
	applicationMode,
}: GetAttributesType) => {
	const { filterSelections, filters: filterCategories } = filters;

	const has3pProducts = !!availableProducts.find((product) => is3pProductKey(product));
	const { frontendExperiments = {}, backendExperiment } = experimentConfig;
	const is3pSearch = !!(
		(has3pProducts && !productSelected) ||
		(productSelected && is3pProductKey(productSelected))
	);

	const archivedStatus = filterSelections.some(
		(selection) => selection.value === OptionIds.ARCHIVED,
	)
		? [CURRENT, ARCHIVED]
		: [CURRENT];

	const commonAttributes: CommonAttributes = {
		searchSessionId,
		productSelected: productSelected || ALL_PRODUCTS_STRING,
		availableProducts,
		mode: applicationMode,
		has3pProducts,
		is3pSearch,
		numberOfTerms: (searchQuery.match(/\w+/g) || []).length,
		queryLength: searchQuery.length,
		inputQueryLength: inputQuery.length,
		queryHash: sha256Hash(searchQuery),
		filters: filterCategories,
		filterSelections,
		containerStatuses: archivedStatus,
		contentStatuses: archivedStatus,
		abTest: backendExperiment,
		frontendExperiments: {
			activeExperiments: Object.keys(frontendExperiments).filter(
				(experiment) => frontendExperiments[experiment]?.serveExperiment,
			),
			...frontendExperiments,
		},
		version: 2,
		api: CROSSPRODUCT_SEARCH_API,
		wordCount: (searchQuery.match(/\w+/g) || []).length,
		isCQL: false,
		queryUpdatedAgo: queryUpdatedTime ? Date.now() - queryUpdatedTime : undefined,
		sort,
	};

	const nonPrivacySafeAttributes = {
		query: searchQuery,
		inputQuery: inputQuery,
	};

	return { commonAttributes, nonPrivacySafeAttributes };
};
